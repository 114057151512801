import React, { useState, useEffect, Fragment } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import bill from '../../assets/images/bill.svg'
import tag from '../../assets/images/tag.svg'
import i from '../../assets/images/i.svg'
import add from '../../assets/images/travel/add.svg'
import deleteSvg from '../../assets/images/delete1.svg';
import parking from '../../assets/images/travel/parking.svg';
import people from '../../assets/images/people.svg'

import toll from '../../assets/images/travel/toll.svg'
import uploadImg from '../../assets/images/uploadImg.svg'
import calender from '../../assets/images/calender.svg'
import distance from '../../assets/images/travel/distance.svg'
import description from '../../assets/images/description.svg'
import Tooltip from '@mui/material/Tooltip';
import { toastContainer, notifyWarning, notifySuccess, notifyError } from '../../components/toast.js';
import { BlueCommonButton, WhiteCommonButton, UploadCommonButton, CancelCommonButton, FunctionalCommonButton, AddButton } from '../../components/button.tsx'
import { expenseUrl, admin, initUrl } from '../../service/url.js';
import { NewServiceCall } from '../../service/config.js';
import LoadingSpinner from "../../components/loader.tsx";
import { EditUpload } from '../teamRequest/editUpload.tsx';
import './createExpenseStyles.css'

import { useSelector, useDispatch } from 'react-redux';
import { setData, selectData } from '../../Redux/features/login/loginSlicer'

import { ShareMealPopup } from './shareMealPopUp.tsx'
import { ShareHotelPopUp } from './shareHotelPopUp.tsx'
import { ViolationPopup } from './violation.tsx';
import { GoogleMaps } from '../../components/googleMapPopup.tsx';
import { startLocationReduxData, endLocationReduxData } from '../../Redux/features/googleLocation/googleLocationSlicer'

import { useNavigate, useLocation, json } from 'react-router-dom';
import { TagPopup } from '../../components/tagPopUp.tsx';
import { RejectedExpenseDetails } from './rejectedDetails.tsx';
import amount from '../../assets/images/travel/amount.svg'
import { BaseImageDownloader } from '../../components/base64Downloader.tsx';
import { downloadImage } from '../../components/downloadFile.js';
import axios from 'axios';
import CustomDropdown from '../../components/dropDown.js';
import DropDownCustom from '../../components/customDropdown.js';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


export const NewCreateExpense = () => {
    const { state } = useLocation();
    const startLocationData = useSelector(startLocationReduxData);
    const endLocationData = useSelector(endLocationReduxData);
    const [startDate, setStartDate] = useState("")
    localStorage.setItem("startDate", startDate);
    const localStartDate = localStorage.getItem("startDate");
    const [endDate, setEndDate] = useState("")
    const [minDate, setMinDate] = useState("")
    const [maxDate, setMaxDate] = useState("")
    const [draftMeal, setDraftMeal] = useState(state?.data[0]?.meals ? [state?.data[0]?.meals] : [])
    const [finalMealDraft, setfinalMealDraft] = useState([])
    console.log(draftMeal, "<<<< draftMeal", finalMealDraft);
    const [draftTravel, setdraftTravel] = useState(state?.data[0]?.travels ? state?.data[0]?.travels : [])
    const [draftHotel, setDraftHotel] = useState(state?.data[0]?.hotels ? state?.data[0]?.hotels : [])
    const [draftDA, setDraftDA] = useState(state?.data[0]?.das ? state?.data[0]?.das : [])
    const [draftOther, setDraftOther] = useState(state?.data[0]?.others ? state?.data[0]?.others : [])
    const [descriptionValue, setDescription] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [expense, setExpense] = useState([])
    const [expenseMode, setExpenseMode] = useState("") // meal, travel, hotel, DA, Other
    const [expenseDetails, setExpenseDetails] = useState<any>([])
    const [expenseWithSubExpense, setExpenseWithSubExpense] = useState([])
    const [expenseMasterData, setExpenseMasterData] = useState<any>([])
    const [expenseMeal, setExpenseMeal] = useState<any>([])
    const [checkbox, setCheckbox] = useState<any>(true)
    const [selectedFile, setSelectedFile] = useState('')
    const [clientSite, setClientSite] = useState(false)
    const mealAmountForWithoutInvoice = 150
    const navigate = useNavigate();
    const loginStatus = useSelector(selectData);

    const [travelData, setTravelData] = useState<any>(
        [
            {
                uid: 0, type: "travel", travel_type: 'local', ex_date: new Date(Date()).toISOString().split('T')[0], vehicle: '2 Wheeler', start_location: '', end_location: '', actual_distance: "", distance_remark: [{ remark: "", policy_id: "" }], amount_remark: [{ remark: "", policy_id: "" }], calculated_distance: "", amount: "", amount_file: '', invoice_file: '',
                parking: [{ pid: 0, value: 0 }], parking_file: [{ pfid: 0, value: "" }],
                has_base_location: "",
            },
            {
                uid: 0, type: "travel", travel_type: 'local', ex_date: new Date(Date()).toISOString().split('T')[0], vehicle: 'Bus', start_location: '', end_location: '', actual_distance: "", distance_remark: [{ remark: "", policy_id: "" }], amount_remark: [{ remark: "", policy_id: "" }], calculated_distance: "", amount: "", amount_file: '', invoice_file: '',
                parking: [{ pid: 0, value: 0 }], parking_file: [{ pfid: 0, value: "" }]
            },
            { uid: 0, type: "travel", travel_type: 'local', ex_date: new Date(Date()).toISOString().split('T')[0], vehicle: 'Taxi/Cab', start_location: '', end_location: '', calculated_distance: "", amount: '', amount_file: '', distance_remark: [{ remark: "", policy_id: "" }], amount_remark: [{ remark: "", policy_id: "" }], invoice_file: '', },
            {
                uid: 0, type: "travel", travel_type: 'local', ex_date: new Date(Date()).toISOString().split('T')[0], vehicle: '4 Wheeler', start_location: '', end_location: '', actual_distance: "", calculated_distance: "", distance_remark: [{ remark: "", policy_id: "" }], amount_remark: [{ remark: "", policy_id: "" }], amount: "", amount_file: '', invoice_file: '',
                parking: [{ pid: 0, value: 0 }],
                has_base_location: "",
                parking_file: [{ pfid: 0, value: "" }],
                toll: [{ tid: 0, value: 0 }],
                toll_file: [{ tfid: 0, value: "" }]
            },
            {
                uid: 0, type: "travel", travel_type: 'local', ex_date: new Date(Date()).toISOString().split('T')[0], vehicle: 'Auto', start_location: '', end_location: '', actual_distance: "", calculated_distance: "", distance_remark: [{ remark: "", policy_id: "" }], amount_remark: [{ remark: "", policy_id: "" }], amount: '', amount_file: '', invoice_file: '',
                parking: [{ id: 0, value: 0 }],
                parking_file: [{ pfid: 0, value: "" }],
                toll: [{ tid: 0, value: 0 }],
                toll_file: [{ tfid: 0, value: "" }]
            },
            { uid: 0, type: "travel", travel_type: 'domestic', ex_date: new Date(Date()).toISOString().split('T')[0], vehicle: 'Bus', start_location: '', end_location: '', distance_remark: [{ remark: "", policy_id: "" }], amount_remark: [{ remark: "", policy_id: "" }], amount: '', amount_file: '', invoice_file: '', },
            { uid: 0, type: "travel", travel_type: 'domestic', ex_date: new Date(Date()).toISOString().split('T')[0], vehicle: 'Flight', start_location: '', end_location: '', distance_remark: [{ remark: "", policy_id: "" }], amount_remark: [{ remark: "", policy_id: "" }], amount: '', amount_file: '', invoice_file: '', },
            { uid: 0, type: "travel", travel_type: 'domestic', ex_date: new Date(Date()).toISOString().split('T')[0], vehicle: 'Train', start_location: '', end_location: '', distance_remark: [{ remark: "", policy_id: "" }], amount_remark: [{ remark: "", policy_id: "" }], amount: '', amount_file: '', invoice_file: '', },
        ]
    )

    const [showCityModal, setshowCityModal] = useState({})
    var [cityData, setcitydata] = useState([])
    var [searchinput, setsearchinput] = useState("")
    const [startLocation, setStartLocation] = useState('')
    const [endLocation, setEndLocation] = useState('')
    const [checkInvoiceMeal, setCheckIncoiceMeal] = useState(false)
    const [previousExpenseDetails, setPreviousExpenseDetails] = useState({});
    const [otherSelectedValue, setOtherSelectedValue] = useState('')
    //** other dropdown */
    const handleOtherChange = (e: any) => {
        // console.log('other chage value????', e.target.value)
        setOtherSelectedValue(e.target.value)
    }
    const getCityList = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: initUrl + admin.cityList,
            headers: {}
        };
        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setcitydata(res?.data?.result)
                    // console.log("getCityList cityData>>", res, cityData)
                }

                // console.log("getCityList reddddddddddddddddds>>>", cityData)
            })
            .catch((err) => {
                setLoading(false)
                // console.log("getGrgetCityListade reerrs>>>", err)
            })
    }

    useEffect(() => {
        // Calculate 60 days ago from today
        const today = new Date();
        const minDate = new Date(today);
        minDate.setDate(1);

        if (today.getDate() <= 10) {
            // Set to the first day of the current month
            minDate.setMonth(today.getMonth() - 2);
        } else {
            // Set to the first day of the previous month
            minDate.setMonth(today.getMonth() - 1);
        }

        // Format the dates as strings in "YYYY-MM-DD" format
        const formattedminDate = minDate.toISOString().split('T')[0];
        const formattedToday = today.toISOString().split('T')[0];

        // Set the state for min and max dates
        setMinDate(formattedminDate);
        setMaxDate(formattedToday);
    }, []);

    useEffect(() => {
        getCityList()
        if (loginStatus?.role?.job_location === "client site") {
            setClientSite(true)
        } else {
            setClientSite(false)
        }
    }, [])

    const [checkvalidcity, setcheckvalidcity] = useState()
    const handleCitytier = (city) => {
        setcheckvalidcity(city)
        setsearchinput("");
        setshowCityModal({})
    }
    const reSetRemark = (uid, resetkeyname, isInvoiceFil: any, isInvoice: any) => {
        if (resetkeyname != "") {
            const updatedRows = expenseDetails.map((row, rowIndex) => row.uid === uid ? { ...row, [resetkeyname]: 150, [`${resetkeyname}_remark`]: [{ remark: "", policy_id: "" }], [isInvoiceFil]: isInvoice } : row);
            setExpenseDetails(updatedRows)
        }
    }
    // *************** meal invoices checkbox *************
    const onChangeInvoice = (uid: any, subex: any, isInvoiceFil: any, isInvoicevalue: any) => {
        setCheckbox((prevStates: any) => ({
            ...prevStates,
            [uid]: {
                ...prevStates[uid],
                [subex]: !prevStates[uid]?.[subex],
            },
        }));

        // console.log("onChangeInvoice[121]>>>", { uid, previousExpenseDetails, subex, isInvoiceFil, isInvoicevalue, checkbox })
        if (!checkbox[uid]?.[subex]) {
            updateMealRow(uid, 150, subex?.toLowerCase(), isInvoiceFil, isInvoicevalue);
            reSetRemark(uid, subex?.toLowerCase(), isInvoiceFil, isInvoicevalue)
        } else {
            updateMealRow(uid, previousExpenseDetails?.[uid]?.[subex?.toLowerCase()] || "", subex?.toLowerCase(), isInvoiceFil, isInvoicevalue);
            reSetRemark(uid, subex?.toLowerCase(), isInvoiceFil, isInvoicevalue)
        }

    }
    //******************* client site checkbox *********/
    const onChangeClientSiteValue = (uid: any, subex: any) => {
        setCheckbox((prevStates: any) => ({
            ...prevStates,
            [uid]: {
                ...prevStates[uid],
                [subex]: !prevStates[uid]?.[subex],
            },
        }));

        if (!checkbox[uid]?.[subex]) {
            updateMealRow(uid, 120, subex?.toLowerCase());
        } else {
            // If the checkbox is unchecked, you might want to handle clearing the unit_cost
            updateMealRow(uid, 0, subex?.toLowerCase());
        }
    };
    // ************** Date handle *************
    const handleInitialDetails = (type: string, value: any) => {
        if (type === "startDate") {
            setStartDate(value)

            let data = [...travelData]
            data[0].ex_date = value
            data[1].ex_date = value
            data[2].ex_date = value
            data[3].ex_date = value
            data[4].ex_date = value
            data[5].ex_date = value
            data[6].ex_date = value

            setTravelData(data)

            setExpenseMode("")
        }
        else if (type === "endDate") {
            if (startDate === "") {
                notifyWarning("Please select start date first!!")
            }
            else {
                if (startDate > value) {
                    notifyWarning("Choose invalid date!!")
                }
                else {
                    setEndDate(value)
                    setExpenseMode("")
                    createMealRowWithDate(value)
                }
            }
        }
        else if (type === "discription") {
            setDescription(value)
        }
    }
    useEffect(() => {
        if (startDate && endDate) {
            createMealRowWithDate(endDate)
        }
    }, [startDate, endDate]);
    useEffect(() => {
        if (state?.data[0]?.status === "draft" || state?.screenFrom == "rejected") {
            setStartDate(state?.data[0]?.start_date ? state?.data[0]?.start_date : '')
            setEndDate(state?.data[0]?.end_date ? state?.data[0]?.end_date : '')
            setDescription(state?.data[0]?.description ? state?.data[0]?.description : '')
            setExpenseMode("")

            console.log(draftMeal?.flat(), "<<< in use effect draftMeal");

            // setfinalMealDraft([...draftMeal.flat()])

            draftMeal.map((item) => {
                setfinalMealDraft(item)
            })
        }
        getMasterExpense();
        getMasterSubExpense();
    }, [])

    const getMasterExpense = async () => {
        setLoading(true)
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: expenseUrl.initialUrl + expenseUrl.getExpenseMaster,
            headers: {},
        };
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setExpense(res?.data?.result)
                }
                else {
                    notifyError("Something went wrong!!")
                }
            })
            .catch((err) => {
                setLoading(false)
                notifyError("Something went wrong!!")
            })
    }
    const [travelFromAPI, setTravelFromAPI] = useState([])
    const [hotelFromAPI, setHotelFromAPI] = useState([])
    const [daFromAPI, setDAFromAPI] = useState([])
    const [otherFromAPI, setOtherFromAPI] = useState([])
    const [travelDrCheck, setTravelDrCheck] = useState(false)
    const storedData = useSelector(setData);
    const userData = storedData?.payload?.login?.items[0]

    const getCreateExpenseWithGrade = async (id: number) => {
        // console.log(expenseDetails , "<<<" ,draftMeal);

        setLoading(true)
        const data = {
            master_expense_id: id,
            grade_name: loginStatus?.role?.grade
        }
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: initUrl + admin.getCreateExpense,
            headers: {},
            data: data
        };
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    console.log(res?.data?.result, "<<<res?.data?.result?.subexpense", expenseDetails);
                    if (res?.data?.result?.master_expense?.expense_name === "Meal") {
                        if (expenseDetails.length == 0) {
                            // // console.log("expenseDetails is empty for meal")
                            customMealSubExpense(res?.data?.result?.subexpense)

                            setExpenseMasterData(res?.data?.result?.subexpense)
                        }
                    }
                    else if (res?.data?.result?.master_expense?.expense_name === "Travel") {
                        // // console.log("Travel>>", res?.data?.result?.subexpense)
                        setTravelFromAPI(res?.data?.result?.subexpense)

                        let tervalmappedData = res?.data?.result?.subexpense?.map((item) => {
                            return {
                                ...item,
                                uid: 0, type: "travel", travel_type: item?.travel_type, ex_date: new Date(Date()).toISOString().split('T')[0],
                                vehicle: item?.subexpense_name, start_location: '', end_location: '', actual_distance: "", distance_remark: [{ remark: "", policy_id: "" }],
                                amount_remark: [{ remark: "", policy_id: "" }], calculated_distance: "", amount: "", amount_file: '', invoice_file: '',
                                parking: [{ pid: 0, value: 0 }],
                                parking_file: [{ pfid: 0, value: "" }],
                                has_invoice: item?.has_invoice, parking_file_invoice: false,
                                toll: item?.subexpense_name == "4 Wheeler" ? [{ tid: 0, value: 0 }] : null,
                                toll_file: item?.subexpense_name == "4 Wheeler" ? [{ tfid: 0, value: "" }] : null
                            }
                        })

                        setTimeout(() => {
                            setTravelDrCheck(true)
                            setTravelData(tervalmappedData)
                            customeForTravel(res?.data?.result?.subexpense)
                        }, 50);
                        console.log(travelData, "<<<<<tervalmappedData", tervalmappedData);
                        // travelData
                    }
                    else if (res?.data?.result?.master_expense?.expense_name === "Hotel") {
                        setHotelFromAPI(res?.data?.result?.master_expense?.policies)
                    }
                    else if (res?.data?.result?.master_expense?.expense_name === "Daily Allowance") {
                        setDAFromAPI(res?.data?.result?.master_expense?.policies)
                    }
                    else if (res?.data?.result?.master_expense?.expense_name === "Other") {
                        setOtherFromAPI(res?.data?.result?.subexpense)
                    }
                }
                else {
                    setExpenseMasterData([])
                    notifyError("Something went wrong!!")
                }
            })
            .catch((err) => {
                setLoading(false)
                setExpenseMasterData([])
                notifyError("Something went wrong!!")
            })
    }

    const [expenseIdData, setexpenseIdData] = useState(0)

    useEffect(() => {
        console.log(expenseDetails , "<<<< meal expenseDetails");
        if(state?.data[0]?.status === "draft"){
            setTimeout(() => {
                getCreateExpenseWithGrade(1)
            }, 50);
        }
    }, [expenseIdData])

    const onclickSetExpenseMode = (name, id) => {
        // console.log(expenseDetails , "<<<" ,draftMeal);
        setshowCityModal({})
        setsearchinput("")
        setexpenseIdData(id)
        setTravelType('')
        if (startDate === "") {
            notifyWarning("Choose start date")
        }
        else if (endDate === "") {
            notifyWarning("Choose end date")
        }
        else if (descriptionValue === "") {
            notifyWarning("Enter description")
        }
        else {
            getCreateExpenseWithGrade(id)
            setExpenseMode(name)

        }
    }
    const clientLocation: string = loginStatus?.role?.job_location

    const customMealSubExpense = (dataFromAPI?: any) => {

        console.log(dataFromAPI , "<<<called FinalResult" ,finalMealDraft);


        if (!dataFromAPI.length) return;
        const data: any = dataFromAPI.reduce((result, subexpense, index) => {
            const subexpenseKey = subexpense.subexpense_name.toLowerCase();
            result[subexpenseKey] = clientLocation === "client site" ? 120 : ""
            result[`${subexpenseKey}_tag`] = '';
            result[`${subexpenseKey}_invoice`] = '';
            result[`${subexpenseKey}_remark`] = [{ remark: '', policy_id: '' }];
            return result;
        }, { uid: 0, type: 'meal', ex_date: '' });
        const existingMealExpenseDetails: object = data
        if (state?.data[0]?.status === "draft" || state?.screenFrom == "rejected") {
            const FinalResult = finalMealDraft.length > 0 ? finalMealDraft.map((data: any, id) => {
                const dynamicKeys = Object.keys(data);  // Get all keys dynamically
                const result: any = { ...existingMealExpenseDetails, ex_date: data?.ex_date || "" };

                dynamicKeys.forEach((key,) => {
                    result[key] = data[key] || "";
                });

                result["uid"] = id;
                result["breakfast_invoice"] = data?.breakfast_invoice_file;
                result["lunch_invoice"] = data?.lunch_invoice_file;
                result["dinner_invoice"] = data?.dinner_invoice_file;

                return result;
            }) : dateArrayValue.map((date, id) => ({
                ...existingMealExpenseDetails,
                ex_date: date,
                uid: id
            }));
            const prevExpenseValues = {};
            const mealCheckboxValue: any = {};

            // Re-create row for dates having no data
            if (FinalResult.length < dateArrayValue.length) {
                const dates_having_data = FinalResult.map(item => item?.ex_date);
                // console.log(dateArrayValue);
                dateArrayValue.filter(el => !dates_having_data.includes(el)).map((date, id) => {
                    return ({
                        ...existingMealExpenseDetails,
                        ex_date: date,
                        uid: FinalResult.length + id
                    })
                }
                ).forEach(item => {
                    const index = FinalResult.findIndex(el => el?.ex_date === item?.ex_date);
                    if (index !== -1) {
                        FinalResult[index] = item;
                    } else {
                        FinalResult.push(item);
                    }
                });
            }

            FinalResult.forEach((data: any) => {
                prevExpenseValues[data?.uid] = data;

                mealCheckboxValue[data.uid] = {};
                if (parseInt(data?.breakfast || 0) === 150) {
                    mealCheckboxValue[data.uid].Breakfast = true;
                }
                if (parseInt(data?.lunch || 0) === 150) {
                    mealCheckboxValue[data.uid].Lunch = true;
                }
                if (parseInt(data?.dinner || 0) === 150) {
                    mealCheckboxValue[data.uid].Dinner = true;
                }
            });

            FinalResult.sort((a, b) => new Date(a?.ex_date) - new Date(b?.ex_date));

            setCheckbox(mealCheckboxValue);
            setPreviousExpenseDetails(prevExpenseValues);

            setExpenseDetails([...FinalResult]);  // meal data
            console.log(FinalResult , "<<<<<<FinalResult");
            
        } else {
            const FinalResult = dateArrayValue.map((date, id) => ({
                ...existingMealExpenseDetails,
                ex_date: date,
                uid: id
            }));

            setExpenseDetails([...FinalResult]);  // meal data
        }

    }

    // base_location_distance calculate logic


    const isbase_location_distance = Number(loginStatus.role?.base_location_distance || 0)
    const [newCalculatedDistance, setnewCalculatedDistance] = useState({})
    const [holdactual_distance, setholdactual_distance] = useState(0)

    const baselocationCal = (isSelected, actual_distance, calculated_distance, uid, item) => {
        //setholdactual_distance(actual_distance)
        //let holdactual_distance = actual_distance
        var validDistance = parseFloat(actual_distance) - Number(loginStatus.role?.base_location_distance || 0)
        if (isSelected == "Yes") {

            let amountData = parseFloat(validDistance) * parseFloat(item?.policies[0]?.unit_cost)
            setholdactual_distance(actual_distance)
            // console.log(actual_distance, amountData,actual_distance, validDistance ,loginStatus.role?.base_location_distance, "holdactual_distance<<<",item?.policies[0]?.unit_cost);


            let checkdistance = (Number(loginStatus.role?.base_location_distance) > Number(actual_distance)) ? 0 : validDistance
            // setnewCalculatedDistance({newCalculatedDistance?.[uid] : isSelected} )
            const updatedRows = travelData.map((row, index) =>
                (row.uid === uid && row.travel_type === travelType && row.vehicle === driveType) ? {
                    ...row, "actual_distance": checkdistance,
                    "has_base_location": isSelected,
                    "amount": (Number(loginStatus.role?.base_location_distance) > Number(actual_distance)) ? 0 : amountData,
                } : row
            )
            setTravelData(updatedRows)
        }
        if (isSelected == "No") {
            let holdDistance = (holdactual_distance != 0) ? holdactual_distance : actual_distance;
            let amountData = parseFloat(holdDistance) * parseFloat(item?.policies[0]?.unit_cost)
            setnewCalculatedDistance(isSelected)

            var validDistance2 = actual_distance - Number(loginStatus.role?.base_location_distance)
            const updatedRows = travelData.map((row, index) =>
                (row.uid === uid && row.travel_type === travelType && row.vehicle === driveType) ? {
                    ...row, "actual_distance": holdDistance,
                    "has_base_location": isSelected,
                    "amount": amountData,
                } : row
            )
            setTravelData(updatedRows)
        }


        // setAmount(uid, item, validDistance, calculated_distance);  
        // // console.log("check..check change updatedRows>>", updatedRows, validDistance)


    }


    const handleMealRow = () => {
        const existingMealExpenseDetails: object = expenseDetails[0]
        const existingMealExpenseDetailsLength = expenseDetails.length

        const newMealRow = { ...existingMealExpenseDetails, ex_date: '', uid: existingMealExpenseDetailsLength }
        const finalMealRowData = [...expenseDetails, newMealRow]
        setExpenseDetails(finalMealRowData)
    }
    const [dateArrayValue, setDateArray] = useState<any>([])
    const createMealRowWithDate = (endDateParam: any) => {
        var date1 = new Date(startDate);
        var date2 = new Date(endDateParam);
        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24) + 1);
        var dateArray = [...Array(Difference_In_Days)].map((_, index) => {
            const newDate = new Date(date1);
            newDate.setDate(date1.getDate() + index);
            return newDate.toISOString()?.split('T')[0];
        });

        setDateArray(dateArray)
    }

    const updateMealRow = (uid: number, value: any, field: string, isInvoiceFil: any, isInvoice: any) => {
        const checkExistDate = field === "ex_date" ? expenseDetails.filter((item) => item.ex_date === value).length : 0

        if (checkExistDate >= 1) {
            notifyError("Date is already added")
        }
        else {
            const updatedRows = expenseDetails.map((row, rowIndex) => row.uid === uid ? { ...row, [field]: value, [isInvoiceFil]: isInvoice } : row);
            setExpenseDetails(updatedRows)
            // // // console.log("date is not available")
        }
    }
    const getMasterSubExpense = async () => {
        setLoading(true)

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: initUrl + admin.getMasterExpenseWithSubexpense,
            headers: {}
        };
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                setExpenseWithSubExpense(res?.data?.result)
                getCreateExpenseWithGrade(res?.data?.result?.find(item => item.expense_name === "Meal")?.id);
                // // // console.log("getMasterSubExpense res>>>", res)
            })
            .catch((err) => {
                setLoading(false)
                setExpenseWithSubExpense([])
                notifyError("Something went wrong!!")
                // // // console.log("getMasterSubExpense reerrs>>>", err)
            })
    }
    const [hotelDetail, setHotelDetails] = useState<any>([
        { uid: 0, type: 'hotel', city: '', check_in_date: '', check_out_date: '', days_count: '', amount: '', invoice_file: '', hotel_tag: '' },
    ])

    const [hotelValidations, setHotelValidations] = useState<any>({});

    // const [validationForHotel, setValidationForHotel] = useState<any>({})
    useEffect(() => {
        if (state?.data[0]?.status !== "draft" || state?.screenFrom == "rejected") {
            setHotelDetails([{ uid: 0, type: 'hotel', city: '', check_in_date: '', check_out_date: '', days_count: '', amount: '', invoice_file: '', hotel_tag: '' }]);
        }
    }, [startDate, endDate])
    const handleHotelRow = (type, index?: any) => {
        if (type === "ADD") {
            const existingHotelExpenseDetailsLength = hotelDetail.length
            const newHotelRow = { type: 'hotel', city: '', check_in_date: '', check_out_date: '', days_count: '', amount: '', invoice_file: '', hotel_tag: '', uid: existingHotelExpenseDetailsLength }
            const finalHotelRowData: object = [...hotelDetail, newHotelRow]
            setHotelDetails(finalHotelRowData)
        }

        if (type === "DELETE") {
            const tempHotelData = [...hotelDetail];
            tempHotelData.splice(index, 1);
            setHotelDetails(tempHotelData)
        }
    }
    const [ammountEnter, setammountEnter] = useState()

    const getDateInUTC: any = (date) => {
        return date ? new Date(new Date(date).toUTCString()) : null;
    };
    const updateHotelRow = (uid: number, value: any, field: string) => {
        let isDateAlreadyUsed = true;
        let validations = { ...hotelValidations };
        const maxNoOfDays = validations?.noOfDays?.[uid];
        if (field == "city") {
            setsearchinput(value)
        }
        if (field == "amount") {
            setammountEnter(value)
        }
        if (field == "days_count") {
            if (maxNoOfDays === undefined) {
                notifyError("Please select a date range");
                return;
            } else if ((value > maxNoOfDays + 1) || (value !== '' && !(value > 0))) {
                notifyError("Invalid no. of days");
                return;
            }
        }
        let updatedRows: any = hotelDetail.map((row) => {
            if (row.uid === uid) {
                return { ...row, [field]: value }
            }
            return row;
        });

        //setHotelDetails(updatedRows)

        const isValidDateRanges = (hotelDates = [], daDates = []) => {
            // Convert date strings to Date objects for both hotel and DA dates
            const hotelDateObjects = hotelDates.map((row: any) => ({
                uid: row.uid,
                type: row.type,
                check_in_date: !isNaN(new Date(row.check_in_date)) ? new Date(row.check_in_date) : '',
                check_out_date: !isNaN(new Date(row.check_out_date)) ? new Date(row.check_out_date) : ''
            }));

            const daDateObjects = daDates?.map((row: any) => ({
                uid: row.uid,
                type: row.type,
                date: !isNaN(new Date(row?.date)) ? new Date(row.date) : ''
            }))


            hotelDateObjects.sort((a, b) => a.check_in_date - b.check_in_date);

            // Check if check_in_date is smaller than or equal to check_out_date for hotel dates
            for (const dateObj of hotelDateObjects) {
                if ((dateObj?.check_in_date && dateObj?.check_out_date) && (dateObj?.check_in_date?.getUTCDate() > dateObj?.check_out_date?.getUTCDate())) {
                    // Invalid date range in hotel dates
                    return false;
                }
            }

            if (daDates.length > 0) {
                // Sort the date ranges based on the start date for both hotel and DA dates
                daDateObjects.sort((a, b) => a.date - b.date);

                // Check for overlapping date ranges between hotel and DA dates
                for (const hotelDate of hotelDateObjects) {
                    for (const daDate of daDateObjects) {
                        if ((hotelDate.check_in_date && hotelDate.check_out_date) && daDate.date && getDateInUTC(hotelDate.check_out_date)?.getUTCDate() >= getDateInUTC(daDate.date)?.getUTCDate() && getDateInUTC(hotelDate.check_in_date)?.getUTCDate() <= getDateInUTC(daDate.date)?.getUTCDate()) {
                            // Overlapping ranges found
                            return false;
                        }
                    }
                }
                if (field == "check_in_date") {
                    for (const hotelDate of hotelDateObjects) {
                        for (const daDate of daDateObjects) {
                            //   // console.log(hotelDate, "<<hotelDate >>daDate", daDate);
                            if ((hotelDate.check_in_date && daDate.date) && getDateInUTC(hotelDate.check_in_date)?.getUTCDate() === getDateInUTC(daDate.date)?.getUTCDate()) {
                                notifyError("Date is already used in Daily Allowance");
                                isDateAlreadyUsed = false;
                                return false;
                            }
                        }
                    }
                }
            }

            // Check for overlapping date ranges within hotel dates
            for (let i = 0; i < hotelDateObjects.length; i++) {
                const currRow: any = hotelDateObjects[i];
                const nextRow: any = hotelDateObjects[i + 1];

                const currentStartDate: any = !isNaN(new Date(currRow?.check_in_date)) ? new Date(currRow.check_in_date).getUTCDate() : '';
                const currentEndDate: any = !isNaN(new Date(currRow?.check_out_date)) ? new Date(currRow.check_out_date).getUTCDate() : '';

                const nextStartDate: any = !isNaN(new Date(nextRow?.check_in_date)) ? new Date(nextRow.check_in_date).getUTCDate() : '';

                if (currentStartDate && currentEndDate) {
                    validations = { ...validations, noOfDays: { ...(validations?.noOfDays || {}), [currRow.uid]: currentEndDate - currentStartDate } };
                }

                if (currentEndDate && nextStartDate && currentEndDate > nextStartDate) {
                    // Overlapping ranges found
                    return false;
                }
            }

            // No overlapping ranges and valid date ranges found
            return true;
        };

        const isValidHotelDataValid = isValidDateRanges(updatedRows, daDetail);

        // // console.log("isValidHotelDataValid>>", isValidHotelDataValid)
        // if isValidHotelDataValid is true means date allowed and false means date is not allowed
        if (!isValidHotelDataValid) {
            updatedRows = hotelDetail.map((row, rowIndex) => row.uid === uid ? {
                ...row, [field]: value,
                [`${"check_in_date"}`]: row.check_in_date !== "" ? "" : row.check_in_date,
                [`${"check_out_date"}`]: row.check_out_date !== "" ? "" : row.check_out_date
            }
                :
                row
            );
            setHotelDetails(updatedRows)
            isDateAlreadyUsed && notifyError("Date is already used");
            return;
        }

        setHotelValidations(validations);

        setHotelDetails(updatedRows);
    }
    const [daDetail, setDADetail] = useState<any>([
        { uid: 0, type: 'da', date: '', city: '', amount: '' }
    ])
    const handleDARow = (type, index?: any) => {
        if (type === "ADD") {
            const existingDAExpenseDetailsLength = daDetail.length
            const newDARow = { uid: existingDAExpenseDetailsLength, type: 'da', date: '', city: '', amount: '' };
            const finalDARowData: object = [...daDetail, newDARow]
            setDADetail(finalDARowData)
        }

        if (type === "DELETE") {
            const tempHotelData = [...daDetail];
            tempHotelData.splice(index, 1);
            setDADetail(tempHotelData);
        }
    }

    //Change not accepted
    const generateDatesInRange = (start: any, end: any) => {
        let startDate = new Date(start);
        let endDate = new Date(end);
        let Difference_In_Time = endDate.getTime() - startDate.getTime();
        let Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24) + 1);
        let dateArray = [...Array(Difference_In_Days)].map((_, index) => {
            const newDate = new Date(startDate);
            newDate.setDate(startDate.getDate() + index);
            return newDate.toISOString()?.split('T')[0];
        });

        return dateArray;
    }

    const updateDARow = (uid: number, value: any, field: string) => {
        if (field == "city") {
            setsearchinput(value)
        }
        if (field == "amount") {
            setammountEnter(value)
        }

        const updatedRows = daDetail.map((row, rowIndex) => row.uid === uid ? { ...row, [field]: value } : row);

        const isValidDAData = (hotelDates, daDates) => {
            // Convert date strings to Date objects for both hotel and DA dates
            const hotelDateObjects = hotelDates.map((row: any) => ({
                uid: row.uid,
                type: row.type,
                check_in_date: !isNaN(new Date(row.check_in_date)) ? new Date(row.check_in_date) : '',
                check_out_date: !isNaN(new Date(row.check_out_date)) ? new Date(row.check_out_date) : ''
            }));

            const daDateObjects = daDates?.map((row: any) => ({
                uid: row.uid,
                type: row.type,
                date: !isNaN(new Date(row?.date)) ? new Date(row.date) : ''
            }))

            // Sort the date ranges based on the start date for both hotel and DA dates
            hotelDateObjects.sort((a, b) => a.check_in_date - b.check_in_date);
            daDateObjects.sort((a, b) => a.date - b.date);

            // Check for overlapping date ranges between hotel and DA dates

            // console.log(hotelDateObjects, "<<<<hotelDateObjects>>", daDateObjects);


            for (const hotelDate of hotelDateObjects) {
                for (const daDate of daDateObjects) {
                    // // console.log(hotelDate , "<<<<hotelDate>>" ,daDate);

                    const hotel_check_in_date = !isNaN(new Date(hotelDate?.check_in_date)) ? new Date(hotelDate?.check_in_date) : '';
                    const hotel_check_out_date = !isNaN(new Date(hotelDate?.check_out_date)) ? new Date(hotelDate?.check_out_date) : '';
                    const da_date = !isNaN(new Date(daDate?.date)) ? new Date(daDate.date) : '';

                    if ((hotel_check_in_date && hotel_check_out_date) && da_date && (hotel_check_out_date?.getUTCDate() >= da_date?.getUTCDate()) && (hotel_check_in_date?.getUTCDate() <= da_date?.getUTCDate())) {
                        return false;
                    }
                }
            }

            if (field == "date") {
                for (const hotelDate of hotelDateObjects) {
                    for (const daDate of daDateObjects) {
                        const hotel_check_in_date = !isNaN(new Date(hotelDate?.check_in_date)) ? new Date(hotelDate?.check_in_date) : '';
                        // const hotel_check_out_date = !isNaN(new Date(hotelDate?.check_out_date)) ? new Date(hotelDate?.check_out_date) : '';
                        const da_date = !isNaN(new Date(daDate?.date)) ? new Date(daDate.date) : '';

                        //   console.log(hotelDate, "<<hotelDate >>daDate", daDate);
                        if ((hotel_check_in_date && da_date) && (hotel_check_in_date?.getUTCDate() === da_date?.getUTCDate())) {
                            notifyError("Date is already used in Hotel");
                            return false;
                        }
                    }
                }
            }

            // Check for duplicate dates in DAData
            //const uniqueDaDates = new Set(daDateObjects.map(date => {
            //    const da_date = !isNaN(new Date(date)) ? new Date(date) : '';
            //    console.log(date, "<<< date" ,da_date);
            //    return da_date?.getUTCDate?.();
            //}));

            //console.log(uniqueDaDates, daDateObjects);

            //if (uniqueDaDates.size !== daDateObjects.length) {
            // Duplicate dates found in DAData
            //    return false;
            //}

            // No overlapping ranges and no duplicate dates found in DAData
            return true;
        };

        const isValidDADataResult = isValidDAData(hotelDetail, updatedRows);
        // console.log("DAData is", isValidDADataResult ? "valid" : "invalid");
        if (!isValidDADataResult) { // invalid date
            // const updatedRows = daDetail.map((row, rowIndex) => row.uid === uid ? {
            //     ...row, [field]: '',
            //     [`${"date"}`]: row.date !== "" ? "" : row.date
            // } : row);
            // setDADetail(updatedRows)
            notifyError("Date is already used")
            return;
        }

        setDADetail(updatedRows);

    }
    const [otherDetail, setOtherDetail] = useState<any>([
        { uid: 0, type: 'other', title: '', amount: '', amount_remark: '', invoice_file: '' }
    ])
    // // console.log("otherDetailotherDetailotherDetailotherDetail>>>", otherDetail)
    const handleOtherRow = () => {
        const existingOtherExpenseDetails: object = otherDetail[0]
        const existingOtherExpenseDetailsLength = otherDetail.length
        const newOtherRow = { type: 'other', title: '', amount: '', amount_remark: '', invoice_file: '', ex_date: '', uid: existingOtherExpenseDetailsLength }
        const finalOtherRowData: any = [...otherDetail, newOtherRow]
        setOtherDetail(finalOtherRowData)
    }
    const updateOtherRow = (uid: number, value: any, field: string) => {
        // // console.log(`updateOtherRow uid= ${uid} and value= ${value} and field= ${field}`)
        const updatedRows = otherDetail.map((row, rowIndex) => row.uid === uid ? { ...row, [field]: value } : row);
        // console.log("updatedRows>>", updatedRows)
        setOtherDetail(updatedRows)
    }
    const updateOtherRemarks = (field: string, value: any) => {
        // // console.log(`updateOtherRemarks field=${field} and value=${value}`)
        const newData = otherDetail.map(item => ({
            ...item,
            remark: value
        }));
        setOtherDetail(newData)
    }
    const [totalSumAmount, setTotalSumAmount] = useState(Number(0))

    const validation = (state: string) => {
        const calculateTotalSum = (data) => {
            let totalSum = 0;
            // Iterate through each object in mealData
            data?.forEach(meal => {
                // Sum up all numeric properties dynamically
                Object.keys(meal).forEach(key => {
                    if ((typeof meal[key] === 'number' && key !== 'uid' && key !== 'type' && key !== 'ex_date' && key !== 'invoice_file' && key !== "id" && key !== "expense_id") || (key === "breakfast" || key === "lunch" || key === "dinner" || key === "amount")) {
                        totalSum += parseFloat(meal[key] || 0);
                    }
                });
            });
            return totalSum;
        }

        const travelSum = (data) => {
            // console.log(data, "231212321")
            let totalAmount = 0;
            let totalParkingValue = 0;
            let totalTollValue = 0;

            data.forEach(travel => {
                Object.keys(travel).forEach(key => {
                    if ((typeof travel[key] === 'number' && key !== 'uid' && key !== 'type' && key !== 'ex_date' && key !== 'amount_file' && key !== 'distance_remark' && key !== 'amount_remark') || (key === "amount")) {
                        totalAmount += isNaN(travel[key]) ? 0 : parseFloat(travel[key] || 0);
                    }
                    if (Array.isArray(travel[key])) {
                        // Check if it's an array and has 'value' property
                        if (travel[key]?.length > 0 && travel?.[key]?.[0]?.value) {
                            travel[key].forEach(item => {
                                totalParkingValue += isNaN(parseFloat(item?.value || 0)) ? 0 : parseFloat(item?.value || 0);
                            });
                        }
                    }
                })
            });
            // console.log('amount totaalla chekc:???????', totalAmount, totalParkingValue, totalTollValue)
            return totalAmount + totalParkingValue + totalTollValue
        }


        const amount = (calculateTotalSum(expenseDetails) + calculateTotalSum(otherDetail) + calculateTotalSum(daDetail) + calculateTotalSum(hotelDetail) + travelSum(travelData))
        setTotalSumAmount(amount);
        setTimeout(() => {
            if (amount === 0) {
                notifyError('Amount is required');
            } else {
                addExpense(amount, state)
            }

        }, 100);
    }
    const [showTag, setShowTag] = useState(false)
    const [showTaghotel, setShowTaghotel] = useState(false)
    const [metaData, setMetaData] = useState({ uidValue: Number(''), tagType: '' })
    const tagFuction = (uid: number, field: string) => {
        setShowTag(true)
        setMetaData({ uidValue: uid, tagType: field })
    }
    const tagFuctionhotel = (uid: number, field: string) => {
        setShowTaghotel(true)
        setMetaData({ uidValue: uid, tagType: field })
    }
    const setShareMealDataFunction = (data: any) => {
        if (Array.isArray(data.newValue)) {
            const result = data.newValue.map(item => item);
            updateMealRow(data.passId.uidValue, result.join(', '), data.passId.tagType)
        }
        else {
            updateMealRow(data.passId.uidValue, data.newValue, data.passId.tagType)
        }
    }
    const setSharehotelDataFunction = (data: any) => {
        if (Array.isArray(data.newValue)) {
            const result = data.newValue.map(item => item);
            updateHotelRow(data.passId.uidValue, result.join(', '), data.passId.tagType)
        }
        else {
            updateHotelRow(data.passId.uidValue, data.newValue, data.passId.tagType)
        }
    }

    const violationDataHandle = (data) => {
        // console.log("violationDataHandle>>", data?.dataValue.policyData.id)
        if (data?.dataValue?.typeName === "meal") {
            updateMealRow(data.dataValue.uid, [{ remark: data.remarks, policy_id: data.dataValue.policyData.id }], `${data.dataValue.field}_remark`)
            // updateMealRow(data.dataValue.uid, data.remarks, `${data.dataValue.field}_remark`)
        }
        else if (data?.dataValue?.typeName === "other") {
            updateOtherRow(data.dataValue.uid, [{ remark: data.remarks, policy_id: data.dataValue.policyData.id }], `${data.dataValue.field}_remark`)
        }
        else if (data?.dataValue?.typeName === "hotel") {
            updateHotelRow(data.dataValue.uid, [{ remark: data.remarks, policy_id: data.dataValue.policyData.id }], `${data.dataValue.field}_remark`)
        }
        else if (data?.dataValue?.typeName === "da") {
            updateDARow(data.dataValue.uid, [{ remark: data.remarks, policy_id: data.dataValue.policyData.id }], `${data.dataValue.field}_remark`)
        }
        else if (data?.dataValue?.typeName === "travel") {
            // updateTravelDetails(`${data.dataValue.field}_remark`, data.remarks, data?.dataValue.uid)
            updateTravelDetails(`${data.dataValue.field}_remark`, [{ remark: data.remarks, policy_id: data.dataValue.policyData.id }], data?.dataValue.uid)
        }
    }
    const cancelViolation = (e) => {
        // console.log("cancelViolation>>", e.dataValue.typeName)
        if (e.type === "close") {
            if (e.dataValue.typeName === "meal") {
                updateMealRow(e.dataValue.uid, Number(0), e.dataValue.field)
            }
            else if (e.dataValue.typeName === "other") {
                updateOtherRow(e.dataValue.uid, Number(0), e.dataValue.field)
            }
            else if (e.dataValue.typeName === "hotel") {
                updateHotelRow(e.dataValue.uid, Number(0), e.dataValue.field)
            }
            else if (e.dataValue.typeName === "da") {
                updateDARow(e.dataValue.uid, Number(0), e.dataValue.field)
            }
            else if (e.dataValue.typeName === "travel") {
                updateTravelDetails(e.dataValue.field, Number(0), e.dataValue.uid)
            }
            setViolation(false)
        }
        else {
            setViolation(false)
        }
    }
    const [showViolation, setViolation] = useState(false)
    const [violationMetaData, setViolationMetaData] = useState({ uid: Number(""), field: "", value: "", limit: "", typeName: "", policyData: [] })
    const setViolationFunction = (uid: number, value: any, field: string, violationLimit: any, typeName: string, policyData: any, messageTitle: any) => {
        // console.log("policyData>>>", policyData)
        setTimeout(() => {
            setViolation(true)
        }, 500)
        setViolationMetaData({ uid: uid, field: field, value: value, limit: violationLimit, typeName: typeName, policyData: policyData, messageTitle })

        if (typeName === "hotel") {
            updateHotelRow(uid, value, 'amount')
        }
        else if (typeName === "meal") {
            updateMealRow(uid, value, field)
        }
        else if (typeName === "da") {
            updateDARow(uid, value, field)
        }
        else if (typeName === "other") {
            updateOtherRow(uid, value, field)
        }
        else if (typeName === "travel") {
            // updateTravelDetails("actual_distance", e.target.value, dataValue.uid)
            updateTravelDetails(field, value, uid)
        }
    }
    const setAmount = (uid: number, item: any, value: any, calculated_distance: any) => {
        // ****************** total_Distance_Amount/unit cost ************//
        if (item?.policies[0]?.unit_cost == "") {
            updateTravelDetails("amount", isNaN(value) ? 0 : value, uid)
        } else {
            let amountData = parseFloat(value) * parseFloat(item?.policies[0]?.unit_cost)
            updateTravelDetails("amount", isNaN(amountData) ? 0 : amountData, uid)
            if(item?.policies[0]?.max_amount != ""){
                if (calculated_distance * item?.policies[0]?.unit_cost > item?.policies[0]?.max_amount) {
                    setViolationFunction(uid, calculated_distance * item?.policies[0]?.unit_cost, 'amount', item?.policies[0]?.max_distance, "travel", item?.policies[0]);
                    // 'travel_policy', item?.policies[0]?.max_distance, "travel", item?.policies[0], `You are not allowed to submit the ${item?.subexpense_name} expense as per your policy`
                }
            }
        }
    }


    useEffect(() => {
        customMealSubExpense([]);
    }, [dateArrayValue.length]);




    useEffect(() => {
        // *********** travel draft values *******************
        if (state?.data != undefined) {
            const mappedData = travelData.map((initialExpense, index) => {
                const savedData = draftTravel.find((draft) => draft?.travel_type === initialExpense?.travel_type && draft?.vehicle === initialExpense?.vehicle);
                if (savedData) {
                    // Extracting amount and parking invoices
                    const hasAmountInvoice = savedData?.invoices?.some((invoice) => (
                        invoice?.ei_type === "amount"
                    ))

                    // console.log("hasTollInvoice", savedData?.invoices?.filter((invoice, id) => invoice?.ei_type === "amount")[0])



                    const hasParkingInvoice = savedData?.invoices?.some((invoice) => invoice?.ei_type === "parking");
                    const hasTollInvoice = savedData?.invoices?.some((invoice) => invoice?.ei_type === "toll");

                    // Mapping data to initialExpense
                    const mappedExpense = {
                        ...initialExpense,
                        uid: index,
                        travel_type: savedData.travel_type || initialExpense?.travel_type,
                        ex_date: savedData.travel_date || initialExpense?.ex_date,
                        actual_distance: savedData?.actual_distance || initialExpense?.actual_distance,
                        calculated_distance: savedData.calculated_distance || initialExpense?.calculated_distance,
                        start_location: savedData.start_location || initialExpense?.start_location,
                        end_location: savedData.end_location || initialExpense?.end_location,
                        distance_remark: savedData.distance_remark || initialExpense?.distance_remark,
                        amount_remark: savedData.amount_remark || initialExpense?.amount_remark,
                        has_base_location: hasAmountInvoice ? savedData?.invoices?.filter((invoice, id) => invoice?.ei_type === "amount")[0]?.has_base_location : initialExpense?.has_base_location,
                        // savedData.has_base_location || initialExpense?.has_base_location,
                        amount: hasAmountInvoice
                            ? savedData?.invoices?.filter((invoice, id) => invoice?.ei_type === "amount")[0]?.amount
                            : initialExpense?.amount,
                        amount_file: hasAmountInvoice
                            ? savedData?.invoices.filter((invoice) => invoice?.ei_type === "amount")[0].invoice_file
                            : initialExpense?.amount_file,
                        parking: hasParkingInvoice
                            ? savedData?.invoices
                                .filter((invoice) => invoice?.ei_type === "parking")
                                .map((parkingInvoice, id) => ({
                                    pid: id,
                                    value: parkingInvoice?.amount
                                }))
                            : [{ pid: 0, value: 0 }],
                        parking_file: hasParkingInvoice
                            ? savedData.invoices
                                .filter((invoice) => invoice?.ei_type === "parking")
                                .map((parkingInvoice, id) => ({
                                    pfid: id,
                                    value: parkingInvoice?.invoice_file
                                }))
                            : [{ pfid: 0, value: "" }],
                        toll: hasTollInvoice
                            ? savedData?.invoices
                                .filter((invoice) => invoice?.ei_type === "toll")
                                .map((tollInvoice, id) => ({
                                    tid: id,
                                    value: tollInvoice?.amount
                                }))
                            : [{ tid: 0, value: 0 }],
                        toll_file: hasTollInvoice
                            ? savedData?.invoices
                                .filter((invoice) => invoice?.ei_type === "toll")
                                .map((tollInvoice, id) => ({
                                    tfid: id,
                                    value: tollInvoice?.invoice_file
                                }))
                            : [{ tfid: 0, value: "" }]
                    };
                    return mappedExpense;
                }
                return initialExpense;
            });
            // console.log(mappedData, "<<<<<<<<mappedData travel differ");
            setTravelData(mappedData);
            // console.log(draftTravel, "<<<<<<<<<draftTravel", state?.data);
        }
    }, [travelDrCheck])





    useEffect(() => {
        // *********** Hotel draft Data value ***********
        const mappedHotelDetails = hotelDetail.map((hotel, index) => {
            const savedHotel = draftHotel[index];
            // console.log(savedHotel, "<<<<<savedHotel");
            if (savedHotel) {
                const amountInvoice = savedHotel.invoices.find((invoice) => invoice?.ei_type === 'amount');
                return {
                    ...hotel,
                    city: savedHotel?.city || '',
                    check_in_date: savedHotel?.check_in_date || '',
                    check_out_date: savedHotel?.check_out_date || '',
                    days_count: savedHotel?.days_count || '',
                    amount: amountInvoice ? amountInvoice?.amount : '',
                    invoice_file: amountInvoice ? amountInvoice?.invoice_file : '',
                    hotel_tag: savedHotel?.hotel_tag || ''
                };
            }
            return hotel;
        });

        // Now, set the mapped hotel details back to the state using setHotelDetails
        setHotelDetails(mappedHotelDetails);

        //  **************** Da Draft data **************

        const mappedDADetails = daDetail.map((da, index) => {
            const savedHotel = draftDA[index];
            if (savedHotel) {
                return {
                    ...da,
                    city: savedHotel?.city || '',
                    date: savedHotel?.ex_date || '',
                    amount: savedHotel?.amount || '',
                };
            }

            return da;
        });

        // Now, set the mapped DA details back to the state using setDADetail
        setDADetail(mappedDADetails);

        //********************** other Data *******************/
        const mappedOtherDetails = (draftOther.length > 0 ? draftOther : otherDetail)?.map((other, index) => {
            const savedOther = draftOther[index];
            if (savedOther) {
                return {
                    ...other,
                    title: savedOther?.title || '',
                    amount: savedOther?.amount || '',
                    amount_remark: savedOther?.remark || '',
                    type: 'other',
                    // invoice_file:savedOther?.invoices[0].value
                };
            }

            return other;
        });
        setOtherDetail(mappedOtherDetails);

    }, [])

    const updateTravelDetails = (field: any, value: any, uid: number) => {

        // const checkExistDate = field === "ex_date" ? travelData.filter((item) => item.ex_date === value).length : 0
        const checkExistDate = field === "ex_date" ?
            travelData.filter((item) => new Date(item.ex_date).getTime() === new Date(value).getTime()).length :
            0;
        // if (checkExistDate >= 1) {
        //     notifyError("Date is already added")
        // }
        // else {
        const updatedRows = travelData.map((row, index) =>
            (row.uid === uid && row.travel_type === travelType && row.vehicle === driveType) ? { ...row, [field]: value } : row
        )
        // console.log("check..check change updatedRows>>", updatedRows, field, value, uid)
        setTravelData(updatedRows)
        // }
    };
    const [valueForLocation, setValueForLocation] = useState("")
    const setGoogleLocation = (value) => {
        if (value.field.field === "start_location") {
            updateTravelDetails(value?.field?.field, value.locationInfo?.name, value?.field?.uid)
            setStartLocation(value.locationInfo?.formatted_address)
        }
        else if (value?.field?.field === "end_location") {
            updateTravelDetails('end_location', value.locationInfo?.name, value?.field?.uid)
            setValueForLocation(value)
            setEndLocation(value.locationInfo?.formatted_address)
        }
    }
    useEffect(() => {
        if (valueForLocation !== "") { handleDistanceMatrixRequest(valueForLocation) }
    }, [valueForLocation])
    const handleDistanceMatrixRequest = async (value) => {
        const apiKey = 'AIzaSyBT3XhTYSxX_qkt_pTejFOrB5TWeFUL6Jk';
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://api.expensereimb.nihonparker.in/api/get-google-distance`,
            headers: {},
            params: {
                origins: `${startLocationData?.startLocation?.lat},${startLocationData?.startLocation?.lng}`,
                destinations: `${endLocationData?.endLocation?.lat},${endLocationData?.endLocation?.lng}`
            }
        };

        await NewServiceCall(config)
            .then((res) => {
                updateTravelDetails('calculated_distance', res.data.rows[0].elements[0].distance.text, value?.field?.uid)
            })
            .catch((err) => {
                console.log("handleDistanceMatrixRequest reerrs>>>", err)
            })
    };
    const [travelTypeDetails, setTravelTypeDetails] = useState<any>([])
    const [travelFirstRender, settravelFirstRender] = useState(false)
    const customeForTravel = (travelFromAPIPassed) => {
        console.log("travelData", travelData);

        setTravelData([...travelData])
        const travelExpense = travelFromAPIPassed?.reduce((result: any, currentSubexpense) => {
            // Find the corresponding travel mode in the result array
            let travelModeObj: any = result.find((mode: any) => mode.travelMode === currentSubexpense.travel_type);
            // If the travel mode doesn't exist, create a new object
            if (!travelModeObj) {
                travelModeObj = {
                    travelMode: currentSubexpense.travel_type,
                    vehicalType: []
                };
                result.push(travelModeObj);
            }

            // Add the vehicle type to the current travel mode
            travelModeObj.vehicalType.push({ title: currentSubexpense.subexpense_name, icon: currentSubexpense.icon });

            return result;
        }, []);
        console.log("travelExpense>>", travelExpense)
        setTravelTypeDetails(travelExpense)

    }
    const [travelType, setTravelType] = useState("")
    const onChangeValueTravel_type = (event: any) => {

        // console.log(travelData , "<<<<");
        setTravelData([...travelData])
        setTimeout(() => {
            setTravelType(event.target.value)
        }, 500);

    }



    const [pMaxAmount, setpMaxAmount] = useState(0)
    const findGradeAmount = async (tags, tierId, exPId, subexpense_id) => {
        var total_limit = 0
        const keysArray = tags?.split(', ')?.map(item => {
            const [key] = item.split(' : ');
            return key.trim(); // Trim to remove any leading/trailing spaces
        }) || [];

        keysArray.push(userData?.empcode);

        const formData = new FormData();
        formData.append('emp_codes', keysArray ? keysArray.join(",") : "");
        formData.append('tier_id', tierId ? tierId : 0);
        formData.append('expense_id', exPId ? exPId : 0);
        formData.append('subexpense_id', subexpense_id ? subexpense_id : 0);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: expenseUrl.initialUrl + "/api/get-expense-policy-data",
            headers: {},
            data: formData
        };

        if (keysArray.length > 1) {
            setLoading(true)
            try {
                const res = await NewServiceCall(config);
                console.log(res.data?.total_expense_limit, "<<<<<<");

                setpMaxAmount(res.data?.total_expense_limit)
                total_limit = res.data?.total_expense_limit;
                setLoading(false);
                if (res.status === 200) {
                    // Any additional logic if needed
                }
            } catch (err) {
                setLoading(false);
                console.log(err, "<<<<<<<keysArray");
            }
        }
        console.log(total_limit, "<<<<<<<<<total_limit");
        // hotelList?.hotel_tag?.split(', ')
        return total_limit
    }


    const [driveType, setDriveType] = useState("")
    const handleTravelDetails = (type: string) => {
        console.log("name>>", travelData)
        setTravelData([...travelData])
        setDriveType(type)

    }
    const handleTravelRow = (type, index, uid) => {
        if (type == "Add") {
            // initialTravelData is replaced travelData
            const temp = travelData.filter((item) => item.travel_type === travelType && item.vehicle === driveType)
            const existingRow = temp[0]
            const existingDataLength = temp.length
            const newRow = {
                ...existingRow, ex_date: '', start_location: '', end_location: '', actual_distance: "", distance_remark: [{ remark: "", policy_id: "" }], amount_remark: [{ remark: "", policy_id: "" }], calculated_distance: "", amount: "", amount_file: '', invoice_file: '',
                parking: [{ pid: 0, value: 0 }], parking_file: [{ pfid: 0, value: "" }],
                has_base_location: "", uid: existingDataLength
            };
            // console.log(newRow , "<<<newRow");
            setTravelData(prevTravelData => [...prevTravelData, newRow]);
            const temptravelData = travelData.filter((item) => item.travel_type === travelType && item.vehicle === driveType && item?.uid == uid)
        }

        // console.log( "<<<travelData",index ,travelData ,uid  );

        if (type == "DELETE") {
            const findItem = travelData.filter((item) => item.travel_type === travelType && item.vehicle === driveType && item.uid === uid)
            setTravelData(prevData => {
                const oldData = prevData.filter((item, index2) => { return (item.travel_type === travelType && item.vehicle === driveType && item.uid !== uid) })
                console.log(oldData, "<<< findItem");
                return prevData;
            });
            console.log(findItem, "<<<findItem");

        }

    };
    const [editUpload, setEditUpload] = useState(false)
    const [fileMetaData, setFileMetaData] = useState({ field: "", uid: Number(''), innerId: Number(""), type: "", subexpense: "" })
    const uploadfilepopup = (field, uid, subex, type) => {
        setEditUpload(true)
        setFileMetaData({ field: field, uid: uid, innerId: Number(''), type: type, subexpense: subex })
    }
    const uploadParkingTollfilepopup = (field: string, uid: number, pid: number, type: string) => {
        setEditUpload(true)
        setFileMetaData({ field: field, uid: uid, innerId: pid, type: type, subexpense: "" })
    }
    const uploadTollfilepopup = (field: string, uid: number, tid: number, type: string) => {
        setEditUpload(true)
        setFileMetaData({ field: field, uid: uid, innerId: tid, type: type })
    }
    const uploadHandleFileChange = (event: any) => {
        if (event?.fileid?.type === "meal") {
            updateMealRow(event?.fileid?.uid, event?.file, event?.fileid?.field)
        }
        else if (event?.fileid?.type === "hotel") {
            updateHotelRow(event?.fileid?.uid, event?.file, event?.fileid?.field)
        }
        else if (event?.fileid?.type === "other") {
            updateOtherRow(event?.fileid?.uid, event?.file, event?.fileid?.field)
        }
        else if (event?.fileid?.type === "travel") {
            // console.log("inside travel>>", event?.fileid?.field, event?.file, event?.fileid?.uid)
            updateTravelDetails(event?.fileid?.field, event?.file, event?.fileid?.uid)
        }
        else if (event?.fileid?.type === "parking") {
            updateParkingFileValueRow(event?.fileid?.innerId, event?.file, event?.fileid?.uid)
        }
        else if (event?.fileid?.type === "toll") {
            updateTollFileValueRow(event?.fileid?.innerId, event?.file, event?.fileid?.uid)
        }
    }

    const [showMap, setShowMap] = useState(false)
    const [loacationFieldName, setLoacationFieldName] = useState({})
    const openMapFunction = (field, uid, item) => {
        setShowMap(true)
        setLoacationFieldName({ "field": field, "uid": uid, item: item })
    }
    const handleTravelParkingRow = (type, pid, uid, actiontype, index) => {
        if (actiontype == "add") {
            setTravelData(prevData => {
                return prevData.map(item => {
                    if (item.travel_type === travelType && item.vehicle === driveType && item.uid === uid) {
                        const newItem: any = { ...item };
                        newItem.parking = [
                            ...newItem.parking,
                            { pid: newItem.parking.length, value: 0 },
                        ];
                        newItem.parking_file = [
                            ...newItem.parking_file,
                            { pfid: newItem.parking_file.length, value: '' }
                        ];
                        return newItem;
                    }
                    return item;
                });
            });
        }
        if (actiontype == "delete") {
            setTravelData(prevData => {
                return prevData.map(item => {
                    if (item.travel_type === travelType && item.vehicle === driveType && item.uid === uid) {
                        const newItem: any = { ...item };
                        newItem.parking = newItem.parking?.filter((item, index2) => index2 != index);
                        newItem.parking_file = [
                            ...newItem.parking_file,
                            { pfid: newItem.parking_file.length, value: '' }
                        ];
                        return newItem;
                    }
                    return item;
                });
            });
        }



    }

    const deletetravelRow = (type, pid, uid, actiontype, index) => {

    }


    const handleTravelTollRow = (type, pid, uid, actiontype, index) => {
        // console.log( uid , actiontype  , "<<<< uid , actiontype" ,index);
        if (actiontype == "delete") {
            setTravelData(prevData => {
                return prevData.map(item => {
                    if (item.travel_type === travelType && item.vehicle === driveType && item.uid === uid) {
                        const newItem: any = { ...item };
                        newItem.toll = newItem.toll?.filter((item, index2) => index2 != index);
                        newItem.toll_file = [
                            ...newItem.toll_file,
                            { pfid: newItem.toll_file.length, value: '' }
                        ];
                        return newItem;
                    }
                    return item;
                });
            });
        }
        if (actiontype == "add") {
            setTravelData(prevData => {
                return prevData.map(item => {
                    if (item.travel_type === travelType && item.vehicle === driveType && item.uid === uid) {
                        const newItem: any = { ...item };
                        newItem.toll = [
                            ...newItem.toll,
                            { tid: newItem.toll.length, value: 0 },
                        ];
                        newItem.toll_file = [
                            ...newItem.toll_file,
                            { tfid: newItem.toll_file.length, value: '' }
                        ];
                        return newItem;
                    }
                    return item;
                });
            });
        }
    }
    const updateParkingValueRow = (pid, value, uid) => {
        const updatedTravelData = travelData.map((travel: any) => {
            if (
                travel.travel_type === travelType &&
                travel.vehicle === driveType &&
                travel.uid === uid
            ) {
                const parkingObj = travel.parking.find((parking) => parking.pid === pid);
                if (parkingObj) {
                    parkingObj.value = value; // Replace 'YourNewValue' with the actual value you want to set
                }
            }
            return travel;
        });
        setTravelData(updatedTravelData);
    }

    const updateParkingFileValueRow = (pid, value, uid) => {
        // // console.log("updateParkingFileValueRow>>", pid, value, uid)
        const updatedTravelData = travelData.map((travel: any) => {
            if (
                travel.travel_type === travelType &&
                travel.vehicle === driveType &&
                travel.uid === uid
            ) {
                const parkingObj = travel.parking_file.find((parking) => parking.pfid === pid);
                // console.log("parkingObj>>", parkingObj)
                if (parkingObj) {
                    parkingObj.value = value; // Replace 'YourNewValue' with the actual value you want to set
                }
            }
            return travel;
        });
        setTravelData(updatedTravelData);
    }

    const updateTollFileValueRow = (pid, value, uid) => {

        const updatedTravelData = travelData.map((travel: any) => {
            if (
                travel.travel_type === travelType &&
                travel.vehicle === driveType &&
                travel.uid === uid
            ) {
                const tollObj = travel.toll_file.find((toll) => toll.tfid === pid);
                // console.log("tollObj>>", tollObj)
                if (tollObj) {
                    tollObj.value = value; // Replace 'YourNewValue' with the actual value you want to set
                }
            }
            return travel;
        });
        setTravelData(updatedTravelData);
    }

    const updateTollValueRow = (fid, value, uid) => {
        const updatedTravelData = travelData.map((travel: any) => {
            if (
                travel.travel_type === travelType &&
                travel.vehicle === driveType &&
                travel.uid === uid
            ) {
                const tollObj = travel.toll.find((toll) => toll.tid === fid);
                if (tollObj) {
                    tollObj.value = value; // Replace 'YourNewValue' with the actual value you want to set
                }
            }
            return travel;
        });
        setTravelData(updatedTravelData);
    }

    // ****************** submit expense Api ******************** 


    const validatedata = (expenseDetails2) => {
        let isVal = true
        expenseDetails2.filter((item) => {
            return item?.type == "meal"
        }).forEach((checkfile) => {
            // console.log(checkfile, "<<<<<checkfile");
            for (let keyname in checkfile) {
                if (checkfile[`${keyname}_Isinvoice`] == true && checkfile[`${keyname}_invoice`] == "") {
                    isVal = false
                    notifyWarning(`Please Upload ${keyname} invoice`)
                    // return false
                }
            }
        })
        return isVal
    }

    const getValidMealData = (mealArray) => {
        const mealCategories = ["breakfast", "lunch", "dinner"];
        const validMealData: any = [];
        for (const meal of mealArray) {
            for (const category of mealCategories) {
                const fieldName = `${category}`;
                const fieldValue = meal[fieldName];
                if (fieldValue && !validMealData.map(el => el.uid).includes(meal.uid)) {
                    validMealData.push(meal);
                }
            }
        }
        return validMealData;
    }

    const validatatravelinvoice = (trData) => {
        var isTrDATAvalid = false
        trData.forEach((items) => {
            console.log(items, "<<<is items");
            if (items?.has_invoice == "yes" && items?.invoice_file == "" && items?.amount != "" && items?.actual_distance != "") {
                notifyWarning("please upload invoice file")
                isTrDATAvalid = true
            }
            if (items?.parking) {
                if (items?.parking[0]?.value != "") {
                    if (items?.parking[0]?.value != '0') {
                        if (items?.parking_file[0]?.value == "") {
                            notifyWarning("please upload parking invoice file")
                            isTrDATAvalid = true
                        }

                    }
                }
            }
            // if(items?.toll){
            //     if(items?.parking[0]?.value != ""){
            //         // console.log(items, "<<<is items");
            //         if(items?.parking_file[0]?.value == ""){
            //             notifyWarning("please upload parking invoice file")
            //             isTrDATAvalid = true
            //         }
            //     }
            // }
        })
        return isTrDATAvalid
    }
    const validattempOtherinvoice = (tempOther) => {
        let isTrDATAvalid = false
        tempOther.forEach((items) => {
            if (items?.amount != "" && items?.invoice_file == "") {
                console.log(items, "<<<is items");
                notifyWarning("Please upload invoice file for others")
                isTrDATAvalid = true
            }
            if (items?.remark == "") {
                notifyWarning("Please fill remark for others")
                isTrDATAvalid = true
            }
            if (items?.title == "") {
                notifyWarning("title is required for others")
                isTrDATAvalid = true
            }

        })
        return isTrDATAvalid
    }
    const validatDaHotelinvoice = (data) => {
        let isTrDATAvalid = false
        data.forEach((items) => {
            if (items?.amount != "" && items?.city != "") {
                if (items?.invoice_file == "") {
                    console.log(items, "<<<is items",);
                    notifyWarning("Please upload invoice file")
                    isTrDATAvalid = true
                }
            }

        })
        return isTrDATAvalid
    }

    const calculateTotalSummeal = (data) => {
        let totalSum = 0;

        data?.forEach(meal => {
            // Sum up all numeric properties dynamically
            Object.keys(meal).forEach(key => {
                if ((typeof meal[key] === 'number' && key !== 'uid' && key !== 'type' && key !== 'ex_date' && key !== 'invoice_file' && key !== "id" && key !== "expense_id") || (key === "breakfast" || key === "lunch" || key === "dinner" || key === "amount")) {
                    totalSum += parseFloat(meal[key] || 0);
                }
            });
        });
        return totalSum;
    }

    const addExpense = async (totalAmount, status) => {

        // let draftedMeal =draftMeal.length  []
        let tempMeal = getValidMealData([...expenseDetails].filter((item: any) => item.ex_date !== ""));
        const tempTravel = [...travelData].filter((item: any) => item.amount !== "")
        // const tempDA = [...daDetail].filter((item) => item.date !== "")
        const tempDA = [...daDetail].filter((item) => item.amount !== "")
        const tempOther = [...otherDetail].filter((item) => item.title !== "")
        const tempHotel = [...hotelDetail].filter((item: any) => item.amount !== "")
        const mergedData = [...tempMeal, ...tempTravel, ...tempOther, ...tempDA, ...tempHotel]
        const mergedData2 = [...tempTravel, ...tempOther, ...tempDA, ...tempHotel]


        const DAHotalData = [...tempDA, ...tempHotel]
        let filteredData = mergedData.filter(item => {
            if (item.type === "travel") {
                return item.amount !== "";
            } else {
                return true;
            }
        })
        console.log(tempMeal, "<<<< tempMeal", mergedData, draftMeal, expenseDetails);

        const newtotalAmount = mergedData2.reduce((initialA, item) => {
            return initialA + Number(item?.amount) + (item?.parking ? Number(item?.parking[0]?.value ? item?.parking[0]?.value : 0) : 0) + (item?.toll ? Number(item?.toll[0]?.value ? item?.toll[0]?.value : 0) : 0)
        }, 0) + calculateTotalSummeal(tempMeal)

        const formData = new FormData();
        formData.append('user_id', userData?.empcode ? userData?.empcode : "");
        formData.append('expense_start_date', startDate ? startDate : "");
        formData.append('expense_end_date', endDate ? endDate : "");
        formData.append('expense_description', descriptionValue ? descriptionValue : "");
        // formData.append('expense_total_amount', totalAmount?.toString());
        formData.append('expense_total_amount', newtotalAmount?.toString());
        formData.append('expenses', JSON.stringify(filteredData, null, 2).toString());
        formData.append('status', status)
        formData.append('prev_expense_id', state !== null && state?.screenFrom === "rejected" ? state?.data[0]?.prev_expense_id : 0)
        formData.append('expense_id', state !== null ? state?.data[0]?.id : '0')
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: expenseUrl.initialUrl  + expenseUrl.addExpense,
            headers: {},
            data: formData
        };

        if (
            validatedata(tempMeal)
            && !validatatravelinvoice(tempTravel)
            && !validatDaHotelinvoice(DAHotalData)
            && !validattempOtherinvoice(tempOther)) {
            setLoading(true)
            NewServiceCall(config)
                .then((res) => {
                    setLoading(false)
                    if (res.status === 200) {
                        notifySuccess(res?.data?.message)
                        setTimeout(() => {
                            navigate('./home')
                        }, 1000)
                    }
                    else {
                        notifyError("Something went wrong!!")
                    }
                })
                .catch((err) => {
                    setLoading(false)
                    notifyError("Something went wrong!!")
                })
        }
    }
    const [tagView, setTagView] = useState(false)
    const [tagList, setTagList] = useState([])
    const tagPeopleView = (list) => {
        setTagView(true)
        setTagList(list)
    }


    useEffect(() => {
        state !== null && state?.screenFrom === "rejected" && rejectDetails(state?.rejecteddataid)
    }, [])
    const [rejectData, setRejectData] = useState([])
    const rejectDetails = async (id: number) => {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: expenseUrl.initialUrl + expenseUrl.resumitExpense,
            headers: {},
            data: { expense_id: id }
        };
        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setRejectData(res?.data?.result)
                }
                else {
                    setRejectData([])
                    notifyError("Something went wrong!!")
                }
                // // console.log("rejectDetails res>>>", res)
            })
            .catch((err) => {
                setLoading(false)
                setRejectData([])
                notifyError("Something went wrong!!")
            })
    }
    const handleImageDownload = (base64, fileName) => {
        // console.log(base64, "<<<base64");

        base64 === "" && notifyError('no file selected')
        base64 !== "" && downloadImage(base64, fileName);
    };



    return (
        <div className='mt-20px'>
            {
                state !== null && state?.screenFrom === "rejected" ?
                    <RejectedExpenseDetails data={rejectData} />
                    :
                    ""
            }
            <LoadingSpinner loading={isLoading} />
            <div className='d-flex row space-between alignItem-center flex-wrap mt-30px m-10px mb-1_5rem'>
                <div className='ml-5px mr-5px'>
                    <span className='bold1Rem commonBlackcolor'>New Expense -&nbsp;</span><span className='light0_813Rem commonGraycolor'>Add your expenses details here</span>
                </div>
                <div className='d-flex'>
                    <div className='ml-5px mr-5px'>
                        <WhiteCommonButton
                            title={`Amount:${state?.data[0]?.total_amount ? state?.data[0]?.total_amount : ''}`}
                            subTitle={totalSumAmount ? totalSumAmount : ""}
                            buttonClick={() => console.log()}
                        />
                    </div>
                </div>
            </div>
            <div className='moduleBorder '>
                <div className='d-flex alignItem-start row'>
                    <div className='m-10px mr-15px'>
                        <TextField
                            id="input-with-icon-textfield"
                            label="Start Date"
                            placeholder='Select date here'
                            type='date'
                            className='datepicker'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" className='ml-10px'>
                                    </InputAdornment>
                                )
                            }}

                            inputProps={{
                                min: minDate,
                                max: maxDate,
                            }}

                            value={startDate}
                            variant="standard"
                            onChange={(e) => handleInitialDetails("startDate", e.target.value)}
                        />
                    </div>

                    <div className='m-10px'>
                        <TextField
                            id="input-with-icon-textfield"
                            label="End Date"
                            placeholder='Select date here'
                            type='date'
                            className='datepicker '
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" className='ml-10px'>
                                    </InputAdornment>
                                ),
                            }}

                            inputProps={{
                                min: minDate,
                                max: maxDate,
                            }}
                            variant="standard"
                            value={endDate}
                            onChange={(e) => handleInitialDetails("endDate", e.target.value)}
                        />
                    </div>
                </div>
                <div className='d-flex column m-10px'>
                    <TextField
                        id="input-with-icon-textfield"
                        label="Expense Description"
                        multiline
                        maxRows={4}
                        placeholder="Max 250 characters"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={description} />
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{ maxLength: 250 }}
                        variant="standard"
                        value={descriptionValue}
                        onChange={(e) => handleInitialDetails("discription", e.target.value)}
                    />
                </div>
            </div>
            <div className='d-flex row alignItem-center flex-wrap mt-30px m-10px mb-30px'>
                <div><span className='bold1Rem commonBlackcolor'>Expense Details -&nbsp;</span><span className='light0_813Rem commonGraycolor'>Add your expenses details step by step here</span></div>
            </div>
            <div className='moduleBorder'>
                {/* Meal, Travel, Hote, DA, Other */}
                <div className='d-flex row flex-wrap m-40px'>
                    {
                        expense.map((expenseList: any, index: any) =>
                            <div key={index}>
                                <FunctionalCommonButton
                                    isActive={expenseList.expense_name === expenseMode}
                                    title={expenseList.expense_name}
                                    onclick={(e: any) => onclickSetExpenseMode(expenseList.expense_name, expenseList.id)} />
                            </div>
                        )
                    }
                </div>
                {
                    expenseMode === "Meal" && expenseDetails.map((item, index) =>
                        <div className='d-flex row mt-30px flex-wrap' key={index}>
                            <div className='m-10px mr-15px date-position'>
                                <TextField
                                    id="input-with-icon-textfield"
                                    label="Date"
                                    placeholder='Enter Date here'
                                    type='date'
                                    className='datepicker'
                                    disabled={false}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" className='ml-10px'>
                                            </InputAdornment>
                                        )
                                    }}
                                    inputProps={{
                                        min: startDate,
                                        max: endDate,
                                    }}
                                    value={item.ex_date}
                                    variant="standard"
                                    onChange={(e) => updateMealRow(item.uid, e.target.value, 'ex_date')}
                                />
                            </div>
                            <div className="d-flex row">
                                {
                                    expenseMasterData.map((subExpDtl, id) =>
                                        <div className='d-flex row flex-wrap' key={id}>
                                            <div className='d-flex column'>
                                                <div className='m-10px date-position' >
                                                    {clientSite &&
                                                        <input value={checkbox} type="checkbox"
                                                            onChange={(e) => onChangeClientSiteValue(item?.uid, subExpDtl.subexpense_name)}
                                                            checked={checkbox[item?.uid]?.[subExpDtl.subexpense_name] || false}
                                                            className='' />
                                                    }
                                                    <TextField
                                                        id={`input-Date-textfield`}
                                                        label={subExpDtl.subexpense_name.charAt(0).toUpperCase() + subExpDtl.subexpense_name.slice(1)}
                                                        type={'number'}
                                                        placeholder={'Enter Amount here'}
                                                        disabled={checkbox[item?.uid]?.[subExpDtl.subexpense_name] || clientSite ? true : false}
                                                        onChange={(e) => {
                                                            setPreviousExpenseDetails((prevStates: any) => ({
                                                                ...prevStates,
                                                                [item?.uid]: {
                                                                    ...prevStates[item?.uid],
                                                                    [subExpDtl?.subexpense_name?.toLowerCase()]: Number(e.target.value),
                                                                },
                                                            }));
                                                            updateMealRow(item?.uid, Number(e.target.value), subExpDtl?.subexpense_name?.toLowerCase(), `${subExpDtl?.subexpense_name?.toLowerCase()}_Isinvoice`, true);
                                                        }}
                                                        onBlur={
                                                            async (e) => {
                                                                let checktagLength = item[`${subExpDtl?.subexpense_name?.toLowerCase()}_tag`] !== "" ? item[`${subExpDtl?.subexpense_name?.toLowerCase()}_tag`]?.split(', ').length : 0
                                                                let policyunit_cost = Number(subExpDtl?.policies[0]?.unit_cost ? subExpDtl?.policies[0]?.unit_cost : 0)
                                                                if (checktagLength > 0) {
                                                                    const maxLimitAmount = await findGradeAmount(item[`${subExpDtl?.subexpense_name?.toLowerCase()}_tag`], 0, expenseIdData, subExpDtl?.id)
                                                                    console.log(pMaxAmount, "<<<<<<pMaxAmount", subExpDtl);
                                                                    if (maxLimitAmount < Number(e.target.value)) {
                                                                        setViolationFunction(item?.uid, Number(e.target.value), subExpDtl?.subexpense_name?.toLowerCase(), maxLimitAmount, "meal", subExpDtl?.policies[0])
                                                                    }
                                                                } else {
                                                                    if (policyunit_cost != 0) {
                                                                        if (policyunit_cost < Number(e.target.value)) {
                                                                            setViolationFunction(item?.uid, Number(e.target.value), subExpDtl?.subexpense_name?.toLowerCase(), subExpDtl?.policies[0]?.unit_cost, "meal", subExpDtl?.policies[0])
                                                                        }
                                                                    }

                                                                }

                                                            }
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start" className='ml-10px'>
                                                                    <img src={bill} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        variant="standard"
                                                        value={item[subExpDtl?.subexpense_name?.toLowerCase()]
                                                        }
                                                    />

                                                    <div className='row d-flex m-10px alignItem-center'>
                                                        <img
                                                            src={tag}
                                                            className='tagImg m-5px'
                                                            onClick={() => {
                                                                tagFuction(item?.uid, `${subExpDtl?.subexpense_name?.toLowerCase()}_tag`)
                                                                updateMealRow(item?.uid, 0, subExpDtl?.subexpense_name?.toLowerCase())
                                                            }}
                                                        />
                                                        <span
                                                            className='curser'
                                                            onClick={() => tagPeopleView(item[`${subExpDtl?.subexpense_name?.toLowerCase()}_tag`]?.split(', '))}>
                                                            <span className='m-5px light0_875Rem'>{item[`${subExpDtl?.subexpense_name?.toLowerCase()}_tag`] !== "" ? item[`${subExpDtl?.subexpense_name?.toLowerCase()}_tag`]?.split(', ').length : ""}</span>
                                                            <span className='m-5px light0_875Rem'>tags</span>
                                                        </span>
                                                        <Tooltip title="tag your meal companions, be it customers or team members, to keep track of your dining experiences." arrow>
                                                            <img src={i} className='iImg m-5px' />
                                                        </Tooltip>
                                                    </div>
                                                    {clientSite ? null
                                                        :
                                                        <>
                                                            <div className='row d-flex m-10px alignItem-center' key={id}>
                                                                <input value={checkbox} type="checkbox"
                                                                    onChange={(e) => onChangeInvoice(item?.uid, subExpDtl.subexpense_name, `${subExpDtl?.subexpense_name?.toLowerCase()}_Isinvoice`, checkbox[item?.uid]?.[subExpDtl.subexpense_name])}
                                                                    checked={checkbox[item?.uid]?.[subExpDtl.subexpense_name] || false}
                                                                    className='' />

                                                                <span className='light1Rem flentBlack'>I don't have Invoice</span>
                                                            </div>
                                                            {checkbox[item?.uid]?.[subExpDtl.subexpense_name] ? "" :
                                                                <div className="">
                                                                    <div className="blueButtonStyle white d-flex alignItem-center curser"
                                                                        onClick={() => uploadfilepopup(`${subExpDtl?.subexpense_name?.toLowerCase()}_invoice`, item.uid, subExpDtl.subexpense_name, "meal")}
                                                                    >
                                                                        <img src={uploadImg} className="ml-5px mr-5px" />
                                                                        <span className="light0_813Rem white ml-5px mr-5px">Upload {subExpDtl.subexpense_name.charAt(0).toUpperCase() + subExpDtl.subexpense_name.slice(1)} Invoice</span>
                                                                    </div>
                                                                    {item[`${subExpDtl?.subexpense_name?.toLowerCase()}_invoice`] == '' ? null :
                                                                        <div className="d-flex curser justfyContent-center">
                                                                            <div className="a">
                                                                                <span className="txtstyle " onClick={() => handleImageDownload(item[`${subExpDtl?.subexpense_name?.toLowerCase()}_invoice`], 'file')}>
                                                                                    View File
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>

                        </div>
                    )
                }
                {
                    expenseMode === "Hotel" && hotelDetail.map((hotelList, index) =>
                        <Fragment key={index}>
                            {
                                // hotelFromAPI?.map((hotelData: any, id) =>
                                <div className='d-flex row mt-30px flex-wrap aa'>
                                    <div className='m-10px date-position position-relative' >
                                        <TextField
                                            id={`input-city-textfield`}
                                            label={'City'}
                                            type={'string'}
                                            placeholder={'Enter City here'}
                                            onClick={() => {
                                                setshowCityModal({ [hotelList.uid]: true })
                                            }}

                                            onChange={(e) => updateHotelRow(hotelList.uid, e.target.value, 'city')}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" className='ml-10px'>
                                                        <img src={bill} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            value={hotelList.city}
                                        />
                                        {
                                            showCityModal[hotelList.uid] &&
                                            <div className='city-modal-style position-absolute w-100' style={showCityModal[hotelList.uid] != true ? { display: "none" } : {}} >
                                                <ul>
                                                    {
                                                        cityData?.filter((item: any) => {
                                                            if (searchinput !== "") {
                                                                return item.name.toLowerCase().includes(searchinput?.toLowerCase())
                                                            }
                                                        }).map((item: any) => {
                                                            return (
                                                                <li style={{ cursor: "pointer" }} onClick={() => {
                                                                    setshowCityModal({ [hotelList.uid]: false })
                                                                    handleCitytier(item)
                                                                    updateHotelRow(hotelList.uid, item?.name, 'city')
                                                                }}>
                                                                    {item?.name}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>

                                        }
                                    </div>

                                    <div className='m-10px mr-15px date-position'>
                                        <TextField
                                            id={`input-checkIN-textfield`}
                                            label={'Check In'}
                                            type='date'
                                            placeholder={'Enter Date here'}
                                            className='datepicker'
                                            disabled={false}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" className='ml-10px'>
                                                    </InputAdornment>
                                                )
                                            }}
                                            inputProps={{
                                                min: startDate,
                                                max: endDate,
                                            }}
                                            defaultValue={startDate}
                                            value={hotelList.check_in_date}
                                            variant="standard"
                                            onChange={(e) => updateHotelRow(hotelList.uid, e.target.value, 'check_in_date')}
                                        />
                                    </div>

                                    <div className='m-10px date-position' >
                                        <TextField
                                            id={`input-checkOut-textfield`}
                                            label={'Check Out'}
                                            type={'date'}
                                            placeholder={'Enter Date here'}
                                            onChange={(e) => updateHotelRow(hotelList.uid, e.target.value, 'check_out_date')}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" className='ml-10px'>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            inputProps={{
                                                min: startDate,
                                                max: endDate,
                                            }}
                                            className='datepicker'
                                            variant="standard"
                                            value={hotelList.check_out_date}
                                        />
                                    </div>
                                    <div className='m-10px date-position' >
                                        <TextField
                                            id={`input-noOfDays-textfield`}
                                            label={'Number of days'}
                                            type={'number'}
                                            placeholder={'Number of days'}
                                            onChange={(e) => updateHotelRow(hotelList.uid, e.target.value, 'days_count')}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" className='ml-10px'>
                                                        <img src={bill} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            value={hotelList.days_count}
                                        />
                                    </div>

                                    {
                                        <div className='m-10px date-position' >
                                            <TextField
                                                id={`input-Amount-textfield`}
                                                label={'Amount'}
                                                type={'number'}
                                                placeholder={'Number amount here'}
                                                onChange={(e) => updateHotelRow(hotelList.uid, Number(e.target.value), 'amount')}
                                                onBlur={
                                                    async (e) => {
                                                        let notfoundedcity = cityData?.filter((item: any) => { if (hotelList.city != "") { return item.name.toLowerCase().includes(hotelList.city?.toLowerCase()) } })
                                                        const checktags = hotelList?.hotel_tag !== "" ? hotelList?.hotel_tag?.split(', ').length : 0
                                                        let foundedcity = hotelFromAPI?.find((item: any) => item.tier_id == checkvalidcity?.city_tier_id)
                                                        if (notfoundedcity.length == 0) {
                                                            if (checktags > 0) {
                                                                const maxLimitAmount = await findGradeAmount(hotelList?.hotel_tag, hotelFromAPI[1]?.tier_id, expenseIdData)
                                                                // console.log(pMaxAmount,"<<<<<<pMaxAmount" ,(pMaxAmount * (Number(hotelList?.days_count) || 1)));
                                                                if ((maxLimitAmount * (Number(hotelList?.days_count) || 1)) < Number(e.target.value)) {
                                                                    setViolationFunction(hotelList?.uid, Number(e.target.value), 'amount', maxLimitAmount, "hotel", hotelFromAPI[1])
                                                                }
                                                                console.log(pMaxAmount, "<<<<<<pMaxAmount1");
                                                            } else {
                                                                let validAmount = (hotelFromAPI[1]?.max_amount * Number(hotelList?.days_count) || 1)
                                                                if (validAmount < Number(e.target.value)) {
                                                                    setViolationFunction(hotelList?.uid, Number(e.target.value), 'amount', hotelFromAPI[1]?.max_amount, "hotel", hotelFromAPI[1])
                                                                }
                                                                console.log(pMaxAmount, "<<<<<<pMaxAmount2");
                                                            }
                                                        } else {
                                                            if (foundedcity?.max_amount != "") {
                                                                if (checktags > 0) {
                                                                    const maxLimitAmount = await findGradeAmount(hotelList?.hotel_tag, foundedcity?.tier_id, expenseIdData)
                                                                    // console.log("<<<<<findGradeAmount(hotelList?.hotel_tag, foundedcity?.tier_id, expenseIdData)",pMaxAmount );
                                                                    if ((maxLimitAmount * Number(hotelList?.days_count) || 1) < Number(e.target.value)) {
                                                                        setViolationFunction(hotelList?.uid, Number(e.target.value), 'amount', maxLimitAmount, "hotel", foundedcity)
                                                                    }
                                                                    console.log(pMaxAmount, "<<<<<<pMaxAmount3");

                                                                } else {
                                                                    let validAmount = (foundedcity?.max_amount * Number(hotelList?.days_count) || 1)
                                                                    if (validAmount < Number(e.target.value)) {
                                                                        setViolationFunction(hotelList?.uid, Number(e.target.value), 'amount', foundedcity?.max_amount, "hotel", foundedcity)
                                                                    }
                                                                    console.log(pMaxAmount, "<<<<<<pMaxAmount4");
                                                                }
                                                            }
                                                        }
                                                    }

                                                }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start" className='ml-10px'>
                                                            <img src={bill} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                                value={hotelList.amount}
                                            />
                                            <br />
                                        </div>
                                    }
                                    <div className='row d-flex m-10px alignItem-center'>
                                        <img
                                            src={tag}
                                            className='tagImg m-5px'
                                            onClick={() => {
                                                updateHotelRow(hotelList.uid, Number(0), 'amount')
                                                tagFuctionhotel(hotelList?.uid, `hotel_tag`)
                                                console.log(hotelList, "<<<hotelList");
                                                // updateMealRow(item?.uid, 0, subExpDtl?.subexpense_name?.toLowerCase())
                                            }}
                                        />
                                        <span
                                            className='curser'
                                            onClick={() => tagPeopleView(hotelList?.hotel_tag?.split(', '))}
                                        >
                                            <span className='m-5px light0_875Rem'>
                                                {hotelList?.hotel_tag !== "" ? hotelList?.hotel_tag?.split(', ').length : ""}
                                            </span>
                                            <span className='m-5px light0_875Rem'>tags</span>
                                        </span>
                                        <Tooltip title="tag your meal companions, be it customers or team members, to keep track of your dining experiences." arrow>
                                            <img src={i} className='iImg m-5px' />
                                        </Tooltip>
                                    </div>
                                    <div className="m-10px">
                                        <div className="blueButtonStyle w-200px white d-flex alignItem-center curser"
                                            onClick={() => uploadfilepopup("invoice_file", hotelList.uid, "", "hotel")}
                                        >
                                            <img src={uploadImg} className="ml-5px mr-5px" />
                                            <span className="light0_813Rem white ml-5px mr-5px">Upload Invoice here</span>
                                        </div>
                                    </div>
                                    {hotelList.invoice_file == "" ? "" :
                                        <div className="d-flex curser justfyContent-center">
                                            <div className="a">
                                                <span className="txtstyle " onClick={() => handleImageDownload(hotelList.invoice_file, 'file')}>
                                                    View File
                                                </span>
                                            </div>
                                        </div>
                                    }
                                    {
                                        hotelDetail?.length !== 1 ?
                                            <div className="ml-20px">
                                                <img src={deleteSvg} width={"40"} onClick={() => handleHotelRow("DELETE", index)}
                                                />
                                            </div>
                                            :
                                            ""
                                    }
                                    {
                                        hotelDetail?.length - 1 === index ?
                                            <div className="ml-20px">
                                                <img src={add}
                                                    onClick={() => handleHotelRow("ADD")}
                                                />
                                            </div>
                                            :
                                            ""
                                    }
                                </div>
                                // )
                            }
                        </Fragment>
                    )
                }

                {
                    expenseMode === "Daily Allowance" && daDetail.map((DAList: any, index) =>
                        <Fragment key={index}>
                            {
                                // daFromAPI?.map((daData: any, id) =>
                                <div className='d-flex row mt-30px flex-wrap aa'>
                                    <div className='m-10px date-position' >
                                        <TextField
                                            id={`input-date-textfield`}
                                            label={'Date'}
                                            type={'date'}
                                            placeholder={'Enter Date here'}
                                            onChange={(e) => updateDARow(DAList.uid, e.target.value, 'date')}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" className='ml-10px'>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            inputProps={{
                                                min: startDate,
                                                max: endDate,
                                            }}
                                            variant="standard"
                                            value={DAList.date}
                                        />
                                    </div>
                                    <div className='m-10px date-position' >
                                        <TextField
                                            id={`input-city-textfield`}
                                            label={'City'}
                                            type={'string'}
                                            placeholder={'Enter City here'}
                                            onChange={(e) => {
                                                updateDARow(DAList.uid, e.target.value, 'city')
                                            }}
                                            onClick={() => {
                                                setshowCityModal({ [DAList.uid]: true })
                                            }}
                                            onBlur={(e) => {
                                                const classAcity = cityData?.filter((item: any) => { if (DAList.city != "") { return item.name.toLowerCase().includes(DAList.city?.toLowerCase()) } })
                                                const policy = daFromAPI?.find((data: any) => data.tier_id == checkvalidcity?.city_tier_id)
                                                // console.log(DAList, "<<<<<DAList" ,classAcity ,policy)
                                                if (classAcity.length == 0) {
                                                    const updatedRows = daDetail.map((row, rowIndex) => row.uid === DAList.uid ? { ...row, "city": DAList.city, "amount": daFromAPI[1]?.max_amount } : row);
                                                    setDADetail(updatedRows)
                                                    // console.log("ClassBCity");
                                                }

                                                // console.log(policy, daFromAPI, classAcity,  "<<<classAcity");


                                            }}

                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" className='ml-10px'>
                                                        <img src={bill} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            value={DAList.city}
                                        />
                                        {
                                            showCityModal[DAList.uid] &&
                                            <div className='city-modal-style' style={showCityModal[DAList.uid] != true ? { display: "none" } : {}} >
                                                <ul>
                                                    {
                                                        cityData?.filter((item: any) => {
                                                            if (searchinput != "") {
                                                                return item.name.toLowerCase().includes(searchinput?.toLowerCase())
                                                            } else {
                                                                return item
                                                            }
                                                        }).map((item: any) => {
                                                            return (
                                                                <li style={{ cursor: "pointer" }} onClick={() => {
                                                                    handleCitytier(item)
                                                                    // updateDARow(DAList.uid, item?.name, 'city')
                                                                    setshowCityModal({ [DAList.uid]: false })
                                                                    const policy = daFromAPI?.find((data: any) => data.tier_id == item?.city_tier_id)
                                                                    const updatedRows = daDetail.map((row, rowIndex) => row.uid === DAList.uid ? { ...row, "city": item?.name, "amount": policy?.max_amount } : row);
                                                                    // console.log(item ,policy , 'amount' ,)
                                                                    setDADetail(updatedRows)

                                                                }}>
                                                                    {item?.name}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    {
                                        <div className='m-10px date-position' >
                                            <TextField
                                                id={`input-Amount-textfield`}
                                                label={'Amount'}
                                                type={'number'}
                                                placeholder={'Number amount here'}
                                                disabled={true}
                                                onChange={(e) => updateDARow(DAList.uid, Number(e.target.value), 'amount')}
                                                //onBlur={
                                                //    (e) => {
                                                //        let checkcity = daFromAPI
                                                //        let notfoundedcity = cityData?.filter((item: any) => { if (searchinput != "") { return item.name.toLowerCase().includes(searchinput?.toLowerCase()) } })
                                                //        if (notfoundedcity.length == 0) {
                                                //            if (daFromAPI[1]?.max_amount < Number(e.target.value)) {
                                                //                setViolationFunction(DAList?.uid, Number(e.target.value), 'amount', daFromAPI[1]?.max_amount, "da", daFromAPI[1])
                                                //            }
                                                //        } else {
                                                //            let foundedcity = daFromAPI?.find((item: any) => item.tier_id == checkvalidcity?.city_tier_id)
                                                //            if (foundedcity?.max_amount < Number(e.target.value)) {
                                                //                setViolationFunction(DAList?.uid, Number(e.target.value), 'amount', daFromAPI?.find((item: any) => item.tier_id == checkvalidcity?.city_tier_id)?.max_amount, "da", daFromAPI?.find((item: any) => item.tier_id == checkvalidcity?.city_tier_id))
                                                //            }
                                                //        }
                                                //    }
                                                //}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start" className='ml-10px'>
                                                            <img src={bill} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                                value={DAList.amount}
                                            />
                                        </div>
                                    }
                                    {
                                        daDetail?.length !== 1 ?
                                            <div className="ml-20px">
                                                <img src={deleteSvg} width={"40"} onClick={() => handleDARow("DELETE", index)}
                                                />
                                            </div>
                                            :
                                            ""
                                    }
                                    {
                                        daDetail?.length - 1 === index ?
                                            <div className="ml-20px">
                                                <img src={add}
                                                    onClick={() => handleDARow("ADD")}
                                                />
                                            </div>
                                            :
                                            ""
                                    }
                                </div>
                                // )
                            }
                        </Fragment>
                    )
                }

                {
                    expenseMode === "Other" && otherDetail.map((OtherList: any, index) =>
                        <Fragment key={index}>
                            {
                                <div className='d-flex row mt-30px flex-wrap aa'>
                                    <div className='m-10px date-position' >
                                        <FormControl sx={{ m: 1, width: 300 }}>
                                            <Select
                                                labelId="demo-multiple-chip-label"
                                                id="demo-multiple-chip"
                                                value={OtherList?.title ? OtherList?.title : 'Title'}
                                                onChange={(e) => updateOtherRow(OtherList.uid, e.target.value, 'title')}
                                            >
                                                {otherFromAPI.map((item, id) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={item?.subexpense_name}
                                                    >
                                                        {item?.subexpense_name ? item?.subexpense_name : ""}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    {
                                        <div className='m-10px date-position' >
                                            <TextField
                                                id={`input-city-textfield`}
                                                label={'Amount'}
                                                type={'number'}
                                                placeholder={'Enter Amount here'}
                                                onChange={(e) => updateOtherRow(OtherList.uid, Number(e.target.value), 'amount')}
                                                onBlur={
                                                    (e) =>
                                                        otherFromAPI?.find((item: any) => item.tier_id == checkvalidcity?.city_tier_id)?.max_amount < Number(e.target.value) ?
                                                            setViolationFunction(OtherList?.uid, Number(e.target.value), 'amount', otherFromAPI?.find((item: any) => item.tier_id == checkvalidcity?.city_tier_id)?.max_amount, "other", otherFromAPI?.find((item: any) => item.tier_id == checkvalidcity?.city_tier_id))
                                                            : ""
                                                }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start" className='ml-10px'>
                                                            <img src={bill} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                                value={OtherList.amount}
                                            />
                                        </div>
                                    }
                                    {
                                        <div className="m-10px">
                                            <div className="blueButtonStyle w-200px white d-flex alignItem-center curser"
                                                onClick={() => uploadfilepopup("invoice_file", OtherList.uid, "", "other")}
                                            >
                                                <img src={uploadImg} className="ml-5px mr-5px" />
                                                <span className="light0_813Rem white ml-5px mr-5px">Upload Invoice here</span>
                                            </div>
                                            {
                                                OtherList.invoice_file !== "" ?
                                                    <div className="d-flex curser justfyContent-center">
                                                        <div className="a">
                                                            <span className="txtstyle " onClick={() => handleImageDownload(OtherList.invoice_file, 'file')}>
                                                                View File
                                                            </span>
                                                        </div>
                                                    </div>
                                                    : ''
                                            }
                                        </div>
                                    }
                                    {
                                        <div className="ml-20px">
                                            <img src={add}
                                                onClick={() => handleOtherRow()}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </Fragment>
                    )
                }
                {
                    expenseMode === "Other" &&
                    <div className='d-flex'>
                        <TextField
                            id={`input-remark-textfield`}
                            label={"Remark"}
                            type={"string"}
                            className='w-100per'
                            multiline
                            maxRows={4}
                            placeholder={`Enter Remarks here`}
                            onChange={(e) => updateOtherRemarks('remarks', e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" className='ml-10px'>
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            value={otherDetail[0]?.remark}
                        />
                    </div>
                }
                {
                    expenseMode === "Travel" &&
                    <div>
                        <span onChange={(e) => onChangeValueTravel_type(e)} className='d-flex'>
                            <div className='radioButtonMealWithCustomer'>
                                <span>
                                    <input type="radio" value="local" name="travel" id='radioforlocalTravel' />
                                </span>
                                <label className='d-flex column alignItem-start' htmlFor='radioforlocalTravel'>
                                    <span className='bold0_875Rem fentBlack'>Local</span>
                                    <span className='light0_875Rem fentBlack'>You are creating this request for a trip
                                        within your home city.</span>
                                </label>
                            </div>
                            <div className='radioButtonMealWithTeam'>
                                <span>
                                    <input type="radio" value="domestic" name="travel" id='radiofordometricTravel' />
                                </span>
                                <label className='d-flex column alignItem-start' htmlFor='radiofordometricTravel'>
                                    <span className='bold0_875Rem fentBlack'>Domestic</span>
                                    <span className='light0_875Rem fentBlack'>You are creating this request for a trip
                                        outside your home city.</span>
                                </label>
                            </div>
                        </span>
                        <div className='d-flex row flex-wrap'>
                            {
                                travelTypeDetails.filter((item) => item.travelMode === travelType)[0]?.vehicalType.map((item, id) =>
                                    <span className='travelButton d-flex alignItem-center curser' key={id}
                                        onClick={() => handleTravelDetails(item.title)}
                                        style={{ backgroundColor: driveType === item.title ? '#027DC2' : '#EFEFEF', color: driveType === item.title ? "#ffff" : "#18181B" }}
                                    >
                                        <img src={expenseUrl.initialUrl + '/' + item.icon} className='ml-5px mr-5px travelIcon' />
                                        <span>{item.title}</span>
                                    </span>
                                )
                            }
                        </div>
                        {
                            travelFromAPI.find((travelRow: any) => travelRow.subexpense_name === driveType && travelRow.travel_type === travelType)?.subexpense_name == driveType &&
                            travelData.filter((item) => item.travel_type === travelType && item.vehicle === driveType).map((dataValue: any, index) => {
                                const checkunitCost = travelFromAPI.find((travelRow: any) => travelRow.subexpense_name === driveType && travelRow.travel_type.toLowerCase() === travelType.toLowerCase())

                                // console.log(travelFromAPI, driveType, travelType, "<<<", travelData);


                                return (
                                    <div key={index}>
                                        {
                                            travelFromAPI.filter((travelRow: any) => travelRow.subexpense_name === driveType && travelRow.travel_type === travelType).map((item: any, id) =>
                                                <div className='d-flex row flex-wrap'>
                                                    <div className='m-10px mr-15px'>
                                                        <TextField
                                                            id="input-with-icon-textfield"
                                                            label="Date"
                                                            placeholder='Enter Date here'
                                                            type='date'
                                                            disabled={false}
                                                            className='datepicker'
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <div>
                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                            {/* {field.title === 'amount' ? <img src={bill} /> : field.title === "Calculated distance" ? <img src={distance} /> : field.title === "date" ? "" : field.title === "amount" ? <img src={bill} /> : <img src={map} />} */}
                                                                        </InputAdornment>
                                                                    </div>
                                                                ),
                                                            }}
                                                            inputProps={{
                                                                min: startDate,
                                                                max: endDate,
                                                            }}
                                                            variant="standard"
                                                            value={dataValue.ex_date}
                                                            onChange={(e) => updateTravelDetails("ex_date", e.target.value, dataValue.uid)}
                                                        />
                                                    </div>
                                                    <div className='m-10px d-flex'>
                                                        <TextField
                                                            id={`input-startLoaction-textfield`}
                                                            label={"Start Location"}
                                                            type={"string"}
                                                            disabled={true}
                                                            multiline
                                                            maxRows={4}
                                                            placeholder={`Choose start location`}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <div
                                                                        onClick={() => openMapFunction('start_location', dataValue.uid, item?.policies[0])}
                                                                    >
                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                            <img src={distance} />
                                                                        </InputAdornment>
                                                                    </div>
                                                                ),
                                                            }}
                                                            variant="standard"
                                                            value={dataValue.start_location}
                                                        />
                                                        {startLocation !== "" &&
                                                            <Tooltip
                                                                key={dataValue.start_location}
                                                                title={dataValue.start_location} arrow >
                                                                <div className="d-flex">
                                                                    <span className='m-5px light0_875Rem '>
                                                                        {/* {'Full address'} */}
                                                                    </span>
                                                                    <img src={i} className='iImg m-5px' />
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                    <div className='m-10px d-flex'>
                                                        <TextField
                                                            id={`input-endLoaction-textfield`}
                                                            label={"End Location"}
                                                            type={"string"}
                                                            disabled={true}
                                                            multiline
                                                            maxRows={4}
                                                            placeholder={`Choose end location`}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <div
                                                                        onClick={() => openMapFunction('end_location', dataValue.uid, item?.policies[0])}
                                                                    >
                                                                        <InputAdornment position="start" className='ml-10px'>
                                                                            <img src={distance} />
                                                                        </InputAdornment>
                                                                    </div>
                                                                ),
                                                            }}
                                                            variant="standard"
                                                            value={dataValue.end_location}
                                                        />

                                                        {endLocation !== "" &&
                                                            <Tooltip
                                                                title={dataValue.end_location} arrow >
                                                                <div className="d-flex">
                                                                    <span className='m-5px light0_875Rem '>
                                                                        {/* {'Full address'} */}
                                                                    </span>
                                                                    <img src={i} className='iImg m-5px' />
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                    {
                                                        item?.has_unit_cost.toLowerCase() == "yes" &&
                                                        <div className='m-10px new-text-class'>
                                                            <TextField
                                                                id={`input-actualDistance-textfield`}
                                                                label={"Actual Distance"}
                                                                type={"number"}
                                                                disabled={((dataValue.actual_distance.length >= 1) && (dataValue.start_location == "" || dataValue.end_location == "")) ? true : !(dataValue?.has_base_location?.toLowerCase() == "yes") || dataValue?.has_base_location == undefined ? false : true}
                                                                placeholder={`Enter actual distance`}
                                                                onBlur={(e) => {
                                                                    console.log(dataValue , "allowed_grades" )
                                                                    
                                                                    if (item?.allowed_grades != null) {
                                                                        if (!item?.allowed_grades.includes(item.policies[0]?.grade_name)) {
                                                                            setViolationFunction(dataValue.uid, Number(e.target.value), 'travel_policy', item?.policies[0]?.max_distance, "travel", item?.policies[0], `You are not allowed to submit the ${item?.subexpense_name} expense as per your policy`);
                                                                        }
                                                                        setAmount(dataValue.uid, item, Number(e.target.value), dataValue.actual_distance);
                                                                    }
                                                                    if (item?.policies[0]?.max_amount != "") {
                                                                        /*    
                                                                            if (parseFloat(dataValue.calculated_distance)) {
                                                                                if (Math.ceil(parseFloat(dataValue.calculated_distance)) < Number(e.target.value)) {
                                                                                    setViolationFunction(dataValue.uid, Number(e.target.value), 'actual_distance', item?.policies[0]?.max_distance, "travel", item?.policies[0], "You have claimed more kilometers then actual distance");
                                                                                }
                                                                            }
    
                                                                            if (item?.policies[0]?.max_amount != "" || item?.policies[0]?.max_distance != "") {
                                                                                if (item?.policies[0]?.max_distance < Number(e.target.value)) {
                                                                                    setViolationFunction(dataValue.uid, Number(e.target.value), 'distance', item?.policies[0]?.max_distance, "travel", item?.policies[0]);
                                                                                }
                                                                            }
                                                                         */
                                                                        setAmount(dataValue.uid, item, Number(e.target.value), dataValue.actual_distance);
                                                                    }

                                                                }}
                                                                onChange={(e) => {
                                                                    updateTravelDetails("actual_distance", e.target.value, dataValue.uid)
                                                                }}
                                                                onClick={() => {
                                                                    if (((dataValue.start_location == "" || dataValue.end_location == ""))) {
                                                                        notifyWarning("Please Enter start and end location first ")
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <div>
                                                                            <InputAdornment position="start" className='ml-10px'>
                                                                                <img src={distance} />
                                                                            </InputAdornment>
                                                                        </div>
                                                                    ),
                                                                }}
                                                                variant="standard"
                                                                value={dataValue.actual_distance}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        item?.has_unit_cost.toLowerCase() == "yes" &&
                                                        <div className='m-10px'>
                                                            <TextField
                                                                id={`input-calculatedDistance-textfield`}
                                                                label={"Calculated Distance"}
                                                                type={"string"}
                                                                disabled={true}
                                                                placeholder={`calculated distance`}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <div>
                                                                            <InputAdornment position="start" className='ml-10px'>
                                                                                <img src={distance} />
                                                                            </InputAdornment>
                                                                        </div>
                                                                    ),
                                                                }}

                                                                variant="standard"
                                                                value={dataValue.calculated_distance}
                                                                onChange={(e) => {
                                                                    // Call setAmount when calculated distance changes
                                                                    setAmount(dataValue.uid, item, Number(e.target.value), dataValue.calculated_distance);
                                                                }}

                                                            />
                                                        </div>
                                                    }

                                                    {item?.has_invoice.toLowerCase() == "yes" ?
                                                        <div className='m-10px'>
                                                            <TextField
                                                                id={`input-amount-textfield`}
                                                                label={"Amount"}
                                                                onClick={() => {
                                                                    if (((dataValue.start_location == "" || dataValue.end_location == ""))) {
                                                                        notifyWarning("Please Enter start and end location first ")
                                                                    }
                                                                }}
                                                                disabled={(((dataValue.amount.toString()).length >= 1) && (dataValue.start_location == "" || dataValue.end_location == "")) ? true : item?.has_unit_cost.toLowerCase() == "no" ? false : true}
                                                                placeholder={`Enter amount here`}
                                                                onChange={(e) => {
                                                                    // Call setAmount when calculated distance changes
                                                                    if((((dataValue.amount.toString()).length >= 1) && (dataValue.start_location == "" || dataValue.end_location == ""))){
                                                                        // setTimeout(() => {
                                                                            setAmount(dataValue.uid, item, Number(0));
                                                                        // }, 50);
                                                                    }
                                                                    setAmount(dataValue.uid, item, Number(e.target.value));
                                                                    // console.log((dataValue.amount.toString()).length,dataValue.start_location,dataValue.amount, "<<<<<<<",dataValue.end_location, ((dataValue.amount.length >= 0) && (dataValue.start_location == "" || dataValue.end_location == "")));


                                                                }}
                                                                onBlur={(e) => {
                                                                    // console.log(dataValue, "<<<<<<<");
                                                                    if (item?.policies[0]?.max_amount != "") {
                                                                        if (item?.policies[0]?.max_amount < Number(e.target.value)) {
                                                                            setViolationFunction(dataValue.uid, Number(e.target.value), 'actual_distance', item?.policies[0]?.max_amount, "travel", item?.policies[0]);
                                                                        }
                                                                    }
                                                                }}

                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <div>
                                                                            <InputAdornment position="start" className='ml-10px'>
                                                                                <img src={amount} />
                                                                            </InputAdornment>
                                                                        </div>
                                                                    ),
                                                                }}

                                                                variant="standard"
                                                                value={dataValue.amount === null ? 0 : dataValue.amount}
                                                            />
                                                        </div> :
                                                        <div className='m-10px'>
                                                            <TextField
                                                                id={`input-amount-textfield`}
                                                                label={"Amount"}
                                                                onClick={() => {
                                                                    if (((dataValue.start_location == "" || dataValue.end_location == ""))) {
                                                                        notifyWarning("Please Enter start and end location first ")
                                                                    }
                                                                }}
                                                                disabled={(((dataValue.amount.toString()).length >= 1) && (dataValue.start_location == "" || dataValue.end_location == "")) ? true : item?.has_unit_cost.toLowerCase() == "no" ? false : true}
                                                                // disabled={item?.has_unit_cost.toLowerCase() == "no" ? false : true}
                                                                placeholder={`enter amount here`}
                                                                onChange={(e) => {
                                                                    // Call setAmount when calculated distance changes
                                                                    setAmount(dataValue.uid, item, Number(e.target.value));
                                                                    // console.log(dataValue , "<<<<<<<");
                                                                    
                                                                }}
                                                                onBlur={(e) => {
                                                                    // console.log(dataValue, "<<<<<<<item?.policies[0]" ,item?.policies[0]);
                                                                    if (item?.policies[0]?.max_amount != "") {
                                                                        if (item?.policies[0]?.max_amount < Number(e.target.value)) {
                                                                            setViolationFunction(dataValue.uid, Number(e.target.value), 'amount', item?.policies[0]?.max_amount, "travel", item?.policies[0]);
                                                                        }
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <div>
                                                                            <InputAdornment position="start" className='ml-10px'>
                                                                                <img src={amount} />
                                                                            </InputAdornment>
                                                                        </div>
                                                                    ),
                                                                }}
                                                                variant="standard"
                                                                value={dataValue.amount === null ? 0 : dataValue.amount}
                                                            />
                                                        </div>
                                                    }

                                                    {
                                                        item?.has_unit_cost.toLowerCase() == "yes" &&
                                                        isbase_location_distance > 0 &&
                                                        <div className="m-2px ">
                                                            <DropDownCustom
                                                                icon={<img src={people} />}
                                                                lable="Base Location Included"
                                                                data={[{ id: 1, name: "Yes" }, { id: 1, name: "No" }]}
                                                                selectedValue={dataValue?.has_base_location ? dataValue?.has_base_location : ""}
                                                                edittype="hasBaseEdit"
                                                                setValue={(e) => {
                                                                    baselocationCal(e?.name, dataValue.actual_distance, dataValue.calculated_distance, dataValue.uid, item)
                                                                    // updateTravelDetails("has_base_location", e?.name, dataValue.uid)
                                                                }}
                                                                viewKeyName="name"
                                                            />
                                                        </div>
                                                    }
                                                    {item?.has_invoice.toLowerCase() == "yes" &&
                                                        <>
                                                            <div className="m-10px">
                                                                <div className="blueButtonStyle w-200px white d-flex alignItem-center curser"
                                                                    onClick={() => uploadfilepopup('invoice_file', dataValue.uid, "", 'travel')}
                                                                >
                                                                    <img src={uploadImg} className="ml-5px mr-5px" />
                                                                    <span className="light0_813Rem white ml-5px mr-5px">Upload Invoice here</span>
                                                                </div>
                                                            </div>
                                                            {
                                                                dataValue?.invoice_file !== "" ?
                                                                    <div className="d-flex curser justfyContent-center">
                                                                        <div className="a">
                                                                            <span className="txtstyle " onClick={() => handleImageDownload(dataValue?.invoice_file, 'file')}>
                                                                                View File
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    : ''
                                                            }
                                                        </>
                                                    }

                                                    <div className="ml-20px">
                                                        <img src={add}
                                                            onClick={() => handleTravelRow("Add", index, dataValue?.uid)}
                                                        />
                                                    </div>
                                                </div>

                                            )
                                        }
                                        {
                                            // (driveType === "4 Wheeler") && 
                                            checkunitCost?.has_unit_cost?.toLowerCase() == "yes" &&
                                            <>
                                                {
                                                    dataValue !== undefined && dataValue?.parking.map((parkingData, index) =>
                                                        <div className='d-flex row flex-wrap' key={index}>
                                                            <div className='m-10px'>
                                                                <TextField
                                                                    id={`input-parking-textfield`}
                                                                    label={"Parking"}
                                                                    type={"number"}
                                                                    disabled={false}
                                                                    placeholder={`enter amount here`}
                                                                    onChange={(e) => updateParkingValueRow(parkingData.pid, Number(e.target.value), dataValue.uid,)}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <div>
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                    <img src={parking} />
                                                                                </InputAdornment>
                                                                            </div>
                                                                        ),
                                                                    }}
                                                                    variant="standard"
                                                                    value={parkingData?.value}
                                                                />
                                                            </div>
                                                            <div className="m-10px">
                                                                <div className="blueButtonStyle w-200px white d-flex alignItem-center curser"
                                                                    onClick={() => uploadParkingTollfilepopup('value', dataValue.uid, parkingData.pid, 'parking')}
                                                                >
                                                                    <img src={uploadImg} className="ml-5px mr-5px" />
                                                                    <span className="light0_813Rem white ml-5px mr-5px">Upload Invoice here</span>
                                                                </div>

                                                                {
                                                                    dataValue?.parking_file.find(item => item.pfid === parkingData.pid).value !== "" ?
                                                                        <div className="d-flex curser justfyContent-center">
                                                                            <div className="a">
                                                                                <span className="txtstyle "
                                                                                    onClick={() => handleImageDownload(dataValue?.parking_file.find(item => item.pfid === parkingData.pid).value, 'file')}
                                                                                >
                                                                                    View File
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        : ''
                                                                }
                                                            </div>
                                                            {
                                                                dataValue?.parking?.length > 1 &&
                                                                <div className="ml-20px">
                                                                    <img src={deleteSvg} width={"40"} onClick={() => {
                                                                        handleTravelParkingRow('parking', parkingData?.pid, dataValue?.uid, "delete", index)
                                                                    }}
                                                                    />
                                                                </div>
                                                            }
                                                            <div className="ml-20px">
                                                                <img src={add}
                                                                    onClick={() => handleTravelParkingRow('parking', parkingData?.pid, dataValue?.uid, "add", index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        }
                                        {
                                            // driveType === "4 Wheeler" && 
                                            checkunitCost?.has_unit_cost.toLowerCase() == "yes" &&
                                            <>
                                                {
                                                    dataValue !== undefined && dataValue?.toll?.map((tollData, index) =>
                                                        <div className='d-flex row flex-wrap' key={index}>
                                                            <div className='m-10px'>
                                                                <TextField
                                                                    id={`input-toll-textfield`}
                                                                    label={"Toll"}
                                                                    type={"number"}
                                                                    disabled={false}
                                                                    placeholder={`enter amount here`}
                                                                    onChange={(e) => updateTollValueRow(tollData?.tid, Number(e.target.value), dataValue?.uid,)}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <div>
                                                                                <InputAdornment position="start" className='ml-10px'>
                                                                                    <img src={toll} />
                                                                                </InputAdornment>
                                                                            </div>
                                                                        ),
                                                                    }}
                                                                    variant="standard"
                                                                    value={tollData?.value}
                                                                />
                                                            </div>
                                                            <div className="m-10px">
                                                                <div className="blueButtonStyle w-200px white d-flex alignItem-center curser"
                                                                    onClick={(e) => uploadTollfilepopup('value', dataValue.uid, tollData.tid, 'toll')}
                                                                >
                                                                    <img src={uploadImg} className="ml-5px mr-5px" />
                                                                    <span className="light0_813Rem white ml-5px mr-5px">Upload Invoice here</span>
                                                                </div>
                                                                {
                                                                    dataValue?.toll_file?.find(item => item.pfid === tollData?.pid).value !== "" ?
                                                                        <div className="d-flex curser justfyContent-center">
                                                                            <div className="a">
                                                                                <span className="txtstyle " onClick={() => handleImageDownload(dataValue?.toll_file?.find(item => item.pfid === tollData?.pid).value, 'file')}>
                                                                                    View File
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        : ''
                                                                }
                                                            </div>
                                                            {
                                                                dataValue?.toll?.length > 1 &&
                                                                <div className="ml-20px">
                                                                    <img src={deleteSvg} width={"40"} onClick={() => {
                                                                        handleTravelTollRow('toll', tollData?.pid, dataValue?.uid, "delete", index)
                                                                    }}
                                                                    />
                                                                </div>
                                                            }
                                                            <div className="ml-20px">
                                                                <img src={add}
                                                                    onClick={() => handleTravelTollRow('toll', tollData?.tid, dataValue?.uid, "add", index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        }

                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                }
            </div>
            <div className='d-flex row justfyContent-end  flex-wrap'>
                <div className='d-flex row flex-wrap'>
                    <div className='m-10px'>
                        <CancelCommonButton
                            title={"Cancel"}
                            buttonClick={() => navigate('/home')}
                        />
                    </div>
                    {
                        state?.screenFrom == "rejected" ? "" :
                            <div className='m-10px'>
                                <WhiteCommonButton
                                    title={"Save for later"}
                                    subTitle={""}
                                    buttonClick={() => validation("draft")}
                                />
                            </div>
                    }
                    <div className='m-10px'>
                        <BlueCommonButton
                            title={"Submit"}
                            subTitle={""}
                            buttonClick={() => {
                                validation("pending")
                            }
                            }
                        />
                    </div>
                </div>
            </div>
            {
                showTag &&
                <ShareMealPopup
                    close={() => setShowTag(false)}
                    passId={metaData}
                    data={(e: any) => setShareMealDataFunction(e)}
                />
            }
            {
                showTaghotel &&
                <ShareHotelPopUp
                    close={() => setShowTaghotel(false)}
                    passId={metaData}
                    data={(e: any) => setSharehotelDataFunction(e)}
                />
            }
            {
                showViolation &&
                <ViolationPopup
                    close={(e) => cancelViolation(e)}
                    data={violationMetaData}
                    submit={(e: any) => {
                        // console.log('violoation datattatta?????????', e)
                        violationDataHandle(e)
                    }}
                />
            }
            {
                editUpload &&
                <EditUpload
                    close={() => setEditUpload(false)}
                    fileId={fileMetaData}
                    passidType={fileMetaData}
                    parkingID={''}
                    // data={(e)=> // console.log("e>>>",e)}
                    data={(e: any) => {
                        // console.log('upoad datattta???????/', e)
                        uploadHandleFileChange(e)
                    }}
                />
            }
            {
                showMap &&
                <GoogleMaps
                    close={() => setShowMap(false)}
                    fieldType={loacationFieldName}
                    passData={(e) => {
                        setGoogleLocation(e)
                    }}
                />
            }
            {
                tagView &&
                <TagPopup
                    close={() => setTagView(false)}
                    data={tagList}
                />

            }
            {toastContainer()}
        </div >
    )
}
