import React, { useState, useEffect } from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import bill from '../../assets/images/bill.svg'
import { TextBoxReact } from '../../components/textBox.tsx';
import { BlueCommonButton, WhiteCommonButton, UploadCommonButton, CancelCommonButton, FunctionalCommonButton, AddButton } from '../../components/button.tsx'
import { NewServiceCall } from '../../service/config.js';
import { admin, initUrl } from '../../service/url.js'
import { toastContainer, notifySuccess, notifyWarning, notifyError } from '../../components/toast.js';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/loader.tsx';

export const EditGradePolicy = () => {
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState<any>([])
    const location = useLocation();
    const { gradedata, gradeid } = location.state || {};
    const navigate = useNavigate();
    const [gradeExpenseData, setGradeExpenseData] = useState([])
    const [mappedData, setMappedData] = useState([])
    const [hotalMappedData, sethotalMappedData] = useState([])
    const [mappedtravelData, settravelMappedData] = useState([])
    const [editpoliceData, seteditpoliceData] = useState()
    // done
    const [cityTier, setCityTier] = useState([])
    const getCityTier = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: initUrl + admin.cityTire,
            headers: {}
        };
        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setCityTier(res?.data?.result ? res?.data?.result : [])
                }
                else {
                    setCityTier([])
                    notifyWarning("Something went wrong!!")
                }
                // console.log("getCityTier res>>>", res)
            })
            .catch((err) => {
                setLoading(false)
                setCityTier([])
                notifyWarning("Something went wrong!!")
                // console.log("getCityTier reerrs>>>", err)
            })
    }
    const getGradePolicy = async () => {
        const formdata = new FormData();
        formdata.append("grade", gradedata);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: initUrl + admin.getgradePolicy,
            headers: {},
            data: formdata
        };
        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res?.data?.responseCode === 200) {
                    setGradeExpenseData(res?.data?.result)
                    let expensePolicy = res?.data?.result
                    const resultArray: any = [];
                    const resultArray2: any = [];
                    var hotalArray: any = [];
                    var policiesarray: any = []

                    expensePolicy.forEach((expenseCategory) => {
                        if (expenseCategory.has_subexpense.toLowerCase() == "no") {
                            // seteditpoliceData(expenseCategory.policies)
                            policiesarray.push(expenseCategory.policies)
                            // cityTier?.forEach((item: any) => {
                            //     console.log(item, "<<<");
                            //     const hotalItem: any = {
                            //         expense_id: expenseCategory.id || "",
                            //         tier_id: item?.id,
                            //         unit_cost: expenseCategory?.has_unit_cost === "yes" ? expenseCategory?.unit_cost || "" : "",
                            //         max_distance: expenseCategory?.has_max_distance === "yes" ? expenseCategory?.max_distance || "" : "",
                            //         max_amount: expenseCategory.policies?.find((item2: any) => item2.tier_id == item.id && item2?.expense_id == expenseCategory?.id)?.max_amount || ""
                            //     };
                            //     hotalArray.push(hotalItem);
                            // })
                            // sethotalMappedData(hotalArray)
                            // console.log(hotalArray, "<<<hotalArray", hotalMappedData);
                        }
                        seteditpoliceData(policiesarray.flat())
                        console.log("<<<policiesarray", policiesarray.flat());

                        if(editpoliceData){
                            editpoliceData?.forEach((item: any) => {
                                console.log(item, "<<<");
                                const hotalItem: any = {
                                    expense_id: item?.expense_id || "",
                                    tier_id: item?.tier_id,
                                    unit_cost: expenseCategory?.has_unit_cost === "yes" ? expenseCategory?.unit_cost || "" : "",
                                    max_distance: expenseCategory?.has_max_distance === "yes" ? expenseCategory?.max_distance || "" : "",
                                    max_amount: item.max_amount || ""
                                };
                                hotalArray.push(hotalItem);
                            })
                            sethotalMappedData(hotalArray)
                            console.log(hotalArray, "<<<hotalArray", hotalMappedData);

                        }
                        if (expenseCategory.has_subexpense === "yes") {
                            expenseCategory.subexpenses.forEach((subexpense) => {

                                if (subexpense.expenses) {
                                    subexpense.expenses.map((item: any) => {
                                        const resultItem2: any = {
                                            expense_id: item?.expense_id || "",
                                            subexpense_id: item.id || "",
                                            unit_cost: item.has_unit_cost === "yes" ? item.unit_cost || "" : "",
                                            max_distance: item.has_max_distance === "yes" ? item.max_distance || "" : "",
                                            max_amount: item.has_max_amount === "yes" ? item.max_amount || "" : "",
                                        }
                                        resultArray2.push(resultItem2);
                                    })
                                    settravelMappedData(resultArray2)
                                } else {

                                    const resultItem: any = {
                                        expense_id: subexpense.expense_id || "",
                                        subexpense_id: subexpense.id || "",
                                        unit_cost: subexpense.has_unit_cost === "yes" ? subexpense.unit_cost || "" : "",
                                        max_distance: subexpense.has_max_distance === "yes" ? subexpense.max_distance || "" : "",
                                        max_amount: subexpense.has_max_amount === "yes" ? subexpense.max_amount || "" : "",
                                    };
                                    resultArray.push(resultItem);
                                }
                            });
                        }
                    });
                    setMappedData(resultArray)
                    sethotalMappedData(hotalArray)
                }
                else {
                    setGradeExpenseData([])
                }
            })
            .catch((err) => {
                setLoading(false)
                setGradeExpenseData([])
            })
    }


    useEffect(() => {
        var hotalArray: any = [];
        var policiesarray: any = []
        gradeExpenseData.forEach((expenseCategory: any) => {
            if (expenseCategory.has_subexpense.toLowerCase() == "no" && expenseCategory.expense_name.toLowerCase() != "other") {
                // console.log(expenseCategory.policies, "<<<expenseCategory");
                policiesarray.push(expenseCategory.policies)
                // cityTier?.forEach((item: any) => {
                //     const hotalItem: any = {
                //         expense_id: expenseCategory.id || "",
                //         tier_id: item?.id,
                //         unit_cost: expenseCategory?.has_unit_cost === "yes" ? expenseCategory?.unit_cost || "" : "",
                //         max_distance: expenseCategory?.has_max_distance === "yes" ? expenseCategory?.max_distance || "" : "",
                //         max_amount: editpoliceData?.find((item2: any) => item2.tier_id == item.id)?.max_amount || ""
                //     };
                //     hotalArray.push(hotalItem);
                // })
                // sethotalMappedData(hotalArray)

                // console.log(hotalArray, "<<<hotalArray", hotalMappedData);
            }
        })
        seteditpoliceData(policiesarray.flat())
        // console.log("<<<policiesarray", policiesarray.flat());

        if(editpoliceData){
            editpoliceData?.forEach((item: any) => {
                console.log(item, "<<<");
                const hotalItem: any = {
                    expense_id: item?.expense_id || "",
                    tier_id: item?.tier_id,
                    unit_cost:   "",
                    max_distance:  "",
                    max_amount: item.max_amount || ""
                };
                hotalArray.push(hotalItem);
            })
            sethotalMappedData(hotalArray)
            // console.log(hotalArray, "<<<hotalArray", hotalMappedData);

        }
    }, [gradeExpenseData])



    const handleDetails = (e: React.ChangeEvent<HTMLInputElement>, id: number, dataValue: any) => {
        const { value } = e.target;
        setMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                // console.log(item.subexpense_id, dataValue.id, 'Matchedd')
                if (item.subexpense_id === dataValue.id) {
                    // console.log('updated datatatat????????', item)
                    return {
                        ...item,
                        unit_cost: value,
                    };
                }
                return item;
            });
        });
        settravelMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                // console.log(item.subexpense_id, dataValue.id, 'Matchedd')
                if (item.subexpense_id === dataValue.id) {
                    // console.log('updated datatatat????????', item)
                    return {
                        ...item,
                        unit_cost: value,
                    };
                }
                return item;
            });
        });

        // console.log(mappedData ,mappedtravelData, "<<<mappedData" ,dataValue ,hotalMappedData);

    };


    const handleDistanceDetails = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const { value } = e.target;
        // console.log('distance value ?????????????', value)
        setMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                if (item.subexpense_id === id) {
                    return {
                        ...item,
                        max_distance: value,
                    };
                }
                return item;
            });
        });
        settravelMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                if (item.subexpense_id === id) {
                    return {
                        ...item,
                        max_distance: value,
                    };
                }
                return item;
            });
        });

    };

    const handleAmountDetails = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const { value } = e.target;

        setMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                if (item.subexpense_id == id) {
                    return {
                        ...item,
                        max_amount: value,
                    };
                }
                return item;
            });
        });
        settravelMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                if (item.subexpense_id == id) {
                    return {
                        ...item,
                        max_amount: value,
                    };
                }
                return item;
            });
        });
    };
    const gradePolicySave = async () => {
        const tempFilteredData = [...mappedData, ...hotalMappedData, ...mappedtravelData].filter((item) => item?.expense_id !== "")
        var formdata = new FormData();
        formdata.append("grade_name", gradedata);
        console.log(tempFilteredData, "<<<tempFilteredData");

        formdata.append("policies", JSON.stringify(tempFilteredData));
        const gradePolicyData = {
            grade_name: gradedata,
            policies: JSON.stringify(tempFilteredData ? tempFilteredData : [])
        }
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: initUrl + admin.editgradePolicy,
            headers: {},
            data: formdata
        };
        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                console.log("gradePolicySave res>>", res)
                if (res?.status === 200) {
                    notifySuccess(res?.data?.message)
                }
                navigate('/admin/gradePolicyList')
                // console.log("getGrade res>>>", res)
            })
            .catch((err) => {
                console.log("getGrade reerrs>>>", err)
            })
    }

    useEffect(() => {
        getCityTier();
        setLoading(true)
    }, [])
    useEffect(() => {
        getGradePolicy()
    }, [])


    const handleExpenseType = (e, arg) => {
        console.log("handleExpenseType e, arg>>", e, arg)
        console.log("handleExpenseType data>>", data)
        const updateData = data.expense.map((item => {
            if (item.type === arg) {
                return { ...item, active: e?.target?.checked ? 1 : 0 };
            }
            return item;
        }));
        const temp = { expense: updateData }
        console.log("handleExpenseType updateData>>", updateData)
        console.log("handleExpenseType temp>>", temp)
        setData(temp)
        return updateData
    }


    const handleExpenseDetails = (e, row, type) => {
        console.log(`handleExpenseDetails e>>${e?.target?.checked} and row>>${row?.expenseType} and type>>${type}`)
        console.log("handleExpenseDetails data>>", data)
        const updatedData = data.expense.map(item => {
            if (item.type === type) {
                console.log("handleExpenseDetails item>>", item.data)
                const updatedInnerData = item.data.map(vehicalData => {
                    if (vehicalData.expenseType === row.expenseType) {
                        console.log("handleExpenseDetails vehicalData>>", vehicalData)
                        return { ...vehicalData, expenseActive: e?.target?.checked ? 1 : 0 };
                    }
                    return vehicalData;
                });
                return { ...item, data: updatedInnerData };
            }
            return item;
        });
        const temp = { expense: updatedData }
        console.log("handleExpenseDetails updatedData>>", temp)
        setData(temp)
    }


    const [daDetails, setDADetails] = useState([{ expense_id: '', tier_id: '', max_amount: '' }])

    const hotelDetailUpdate = (e: React.ChangeEvent<HTMLInputElement>, dataValue: any, tier: any) => {
        const { value } = e.target;
        sethotalMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                // console.log(item.expense_id, dataValue, 'Matchedd', tier)
                // if(item.tier_id != tier.id ){
                if (item.tier_id == tier.id && item.expense_id == dataValue.id) {
                    console.log('updated datatatat????????', item)
                    return {
                        ...item,
                        max_amount: value,
                    };
                }
                return item;
                // }
            });
        });
        console.log(hotalMappedData, "<<<mappedData");
    };



    return (
        <div>
            {
                isLoading ?
                    <LoadingSpinner loading={isLoading} />
                    :
                    <>
                        <div className='moduleBorderWithoutPadding'>
                            <div className='m-10px textAlign-Start column'>
                                <span className="bold1Rem commonBlackcolor">Select Grade from this list</span>
                                <div>
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            value={gradedata}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            disabled={gradedata === undefined ? false : true}
                                        >
                                            <MenuItem value="" disabled>
                                                {gradedata}
                                            </MenuItem>
                                            <MenuItem key={gradeid} value={gradedata}>
                                                {gradedata}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <span className="bold1_26Rem commonBlackcolor d-flex m-10px justfyContent-start textAlign-Start">Expense Types</span>
                        {gradeExpenseData.map((expense: any, index) =>
                            <div className='moduleBorderWithoutPadding' key={index}>
                                <div className='d-flex column m-10px justfyContent-start textAlign-Start'>
                                    <div className='bold1Rem commonBlackcolor '>Set Policy for {expense?.expense_name}</div>
                                    <div>
                                        <div className='m-10px'>
                                            <div className='d-flex column nn'>
                                                <div className='addButton'>
                                                    <div className="localBg white d-flex align-center justfyContent-center addButton">
                                                        <span className="light1Rem flentBlack mr-10px">{expense?.expense_name.charAt(0).toUpperCase() + expense?.expense_name.slice(1)}</span>
                                                    </div>
                                                </div>
                                                <>
                                                    {expense?.subexpenses.length > 0 && expense?.subexpenses[0]?.travelType &&
                                                        <>
                                                            {expense?.subexpenses?.map((item: any, key) => (
                                                                <div className='d-flex column aa mt-2px' key={key}>
                                                                    <div className='d-flex alignItem-center m-20px w-15per'>
                                                                        <span className='light1Rem flentBlack m-10px localBg p-10px'>{item?.travelType}</span>
                                                                    </div>
                                                                    {item?.expenses?.map((dataValue: any, id: any) =>
                                                                        <div className='tt d-flex'>
                                                                            <div>
                                                                                <div className='qq d-flex column'>
                                                                                    <div className='d-flex'>
                                                                                        <div className='d-flex alignItem-center ml-20px'>
                                                                                            {dataValue?.has_unit_cost === 'yes' &&

                                                                                                <div className='w-15per alignItem-center '>
                                                                                                    <span className='light1Rem flentBlack m-10px'>{dataValue?.subexpense_name}</span>
                                                                                                </div>}
                                                                                        </div>

                                                                                        {dataValue?.has_unit_cost === 'yes' &&
                                                                                            <div className='ww mb-20px mr-20px d-flex '>
                                                                                                <div key={id} className='d-flex bb column'>
                                                                                                    <TextBoxReact
                                                                                                        multiline={false}
                                                                                                        labelText={`Unit cost`}
                                                                                                        img={<img src={bill} />}
                                                                                                        onchangeText={(e: any) => handleDetails(e, dataValue.id, dataValue)}
                                                                                                        placeholder={`Unit cost`}
                                                                                                        defaultValue={dataValue?.unit_cost}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        {dataValue?.has_max_distance === 'yes' &&
                                                                                            <div className='ww mb-20px mr-20px'>
                                                                                                <div key={id} className='d-flex row bb'>
                                                                                                    <TextBoxReact
                                                                                                        multiline={false}
                                                                                                        labelText={"Maximum Distance (KM)"}
                                                                                                        img={<img src={bill} />}
                                                                                                        onchangeText={(e: any) => handleDistanceDetails(e, dataValue?.id)}
                                                                                                        placeholder={`Enter value`}
                                                                                                        defaultValue={dataValue?.max_distance}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        {dataValue?.has_max_amount === 'yes' &&
                                                                                            <div className='ww mb-20px'>
                                                                                                <div key={id} className='d-flex row bb'>
                                                                                                    <TextBoxReact
                                                                                                        multiline={false}
                                                                                                        labelText={'Maximum Amount'}
                                                                                                        img={<img src={bill} />}
                                                                                                        onchangeText={(e: any) => handleAmountDetails(e, dataValue?.id,)}
                                                                                                        placeholder={`Enter value`}
                                                                                                        defaultValue={dataValue?.max_amount}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </>
                                                    }
                                                    {expense?.subexpenses.length > 0 && !expense?.subexpenses[0]?.travelType &&
                                                        <div className='qq d-flex column'>
                                                            {expense?.subexpenses?.map((item: any, id) => (
                                                                <div className='d-flex'>
                                                                    <div className='w-15per mr-20px '>
                                                                        <span className='light1Rem flentBlack m-10px mr-20px'>{item?.subexpense_name}</span>
                                                                    </div>
                                                                    <div>
                                                                    </div>
                                                                    {item?.has_unit_cost === 'yes' &&
                                                                        <div className='ww mb-20px '>
                                                                            <div key={id} className='d-flex row bb'>
                                                                                <TextBoxReact
                                                                                    multiline={false}
                                                                                    labelText={`Unit cost`}
                                                                                    img={<img src={bill} />}
                                                                                    onchangeText={(e: any) => handleDetails(e, item.id, item, "unitCost")}
                                                                                    placeholder={`Unit cost`}
                                                                                    defaultValue={item?.unit_cost}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {item?.has_max_distance === 'yes' &&
                                                                        <div className='ww mb-20px ml-20px'>
                                                                            <div key={id} className='d-flex row bb'>
                                                                                <TextBoxReact
                                                                                    multiline={false}
                                                                                    labelText={"Maximum Distance (KM)"}
                                                                                    img={<img src={bill} />}
                                                                                    onchangeText={(e: any) => handleDistanceDetails(e, item?.id)}
                                                                                    // onchangeText={(e: any) => handleDetails(e, item.id, item, "max_distance")}
                                                                                    placeholder={`Enter value`}
                                                                                    defaultValue={item?.max_distance}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {item?.has_max_amount === 'yes' &&
                                                                        <div className='ww mb-20px ml-20px'>
                                                                            <div key={id} className='d-flex row bb'>
                                                                                <TextBoxReact
                                                                                    multiline={false}
                                                                                    labelText={'Maximum Amount'}
                                                                                    img={<img src={bill} />}
                                                                                    onchangeText={(e: any) => handleAmountDetails(e, item?.id,)}
                                                                                    // onchangeText={(e: any) => handleDetails(e, item.id, item, "max_amount")}
                                                                                    placeholder={`Enter value`}
                                                                                    defaultValue={item?.max_amount}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }
                                                </>

                                                <>
                                                    {expense?.subexpenses.length == 0 && expense?.has_subexpense.toLowerCase() == "no" && expense?.expense_name.toLowerCase() != "other" &&
                                                        <>
                                                            {cityTier.map((tier: any, index) => {

                                                                console.log(editpoliceData, editpoliceData?.find((item: any) => {
                                                                    return item.tier_id == tier.id && item.expense_id == expense.id
                                                                })?.max_amount, "<<<editpoliceData<<<a")


                                                                return (
                                                                    <div className='m-10px d-flex row'>
                                                                        <div className='m-10px'>
                                                                            <div className='mb-20px '>
                                                                                <div key={index} className='d-flex row bb'>
                                                                                    <TextBoxReact
                                                                                        multiline={false}
                                                                                        labelText={`City Tier`}
                                                                                        img={<img src={bill} />}
                                                                                        disabled={true}
                                                                                        placeholder={`City Tier`}
                                                                                        value={tier?.name}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='m-10px'>
                                                                            <div className='d-flex'
                                                                                key={index}
                                                                            >
                                                                                <TextBoxReact
                                                                                    multiline={false}
                                                                                    labelText={'Maximum amount '}
                                                                                    img={<img src={bill} />}
                                                                                    onchangeText={(e: any) => hotelDetailUpdate(e, expense, tier)}
                                                                                    placeholder={`Enter price`}
                                                                                    defaultValue={
                                                                                        editpoliceData ? editpoliceData?.find((item: any) => {
                                                                                            return item.tier_id == tier.id && item.expense_id == expense.id
                                                                                        })?.max_amount : ""
                                                                                    }
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                )

                                                            }
                                                            )}

                                                        </>
                                                    }
                                                </>
                                            </div >
                                            {/* )} */}
                                        </div>

                                    </div>
                                    {toastContainer()}
                                </div>

                            </div>
                        )}
                        <div className='d-flex row justfyContent-end  flex-wrap'>
                            <div className='d-flex row flex-wrap'>
                                <div className='m-10px'>
                                    <CancelCommonButton
                                        title={"Cancel"}
                                        buttonClick={() => navigate('/admin/gradePolicyList')}
                                    />
                                </div>
                                <div className='m-10px'>
                                    <BlueCommonButton
                                        title={"Submit"}
                                        subTitle={""}
                                        buttonClick={() => gradePolicySave()}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
            }

        </div>
    )
}