import React, { useState, useEffect } from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import bill from '../../assets/images/bill.svg'
import { TextBoxReact } from '../../components/textBox.tsx';
import { BlueCommonButton, CancelCommonButton } from '../../components/button.tsx'
import { NewServiceCall } from '../../service/config.js';
import { admin, initUrl } from '../../service/url.js'
import { toastContainer, notifySuccess, notifyWarning, notifyError } from '../../components/toast.js';
import { json, useLocation } from 'react-router-dom';
import LoadingSpinner from '../../components/loader.tsx';
import { Link, useNavigate } from 'react-router-dom';

export const GradePolicy2 = () => {
    const [grade, setGrade] = useState('');
    const [isLoading, setLoading] = useState(false)
    const [gradeExpenseData, setGradeExpenseData] = useState([])
    const [mappedData, setMappedData] = useState([])
    const [hotalMappedData, sethotalMappedData] = useState([])
    const [dailyAllownceData, setdailyAllownceData] = useState([])
    const [mappedtravelData, settravelMappedData] = useState([])
    const location = useLocation();
    const [travelType, setTravelType] = useState("")
    const [gradeList, setGradeList] = useState([]);
    const [daTier, setDaTier] = useState('')
    const [hoteltier, sethoteltier] = useState('')
    const [DaValue, setDaValue] = useState('')
    const [hotelvalue, setHotelValue] = useState('')
    const [hotelDetails, setHotelDetails] = useState([{ expense_id: '', tier_id: '', max_amount: '' }])
    const [daDetails, setDADetails] = useState([{ expense_id: '', tier_id: '', max_amount: '' }])
    const navigate = useNavigate();

    const handleChangeHotel = (event: SelectChangeEvent) => {
        const newData = [...hotelDetails];
        newData[0].tier_id = event.target.value;
        sethoteltier(event.target.value)
        setHotelDetails(newData);
    };
    const handleChangeDa = (event: SelectChangeEvent) => {
        const newData = [...daDetails];
        newData[0].tier_id = event.target.value;
        setDADetails(newData)
        setDaTier(event.target.value)
        console.log("event.target.value>>>", event.target.value)
    };
    useEffect(() => {
        setTravelType('');
        getCityTier();
        setLoading(true)
    }, [])

    const [cityTier, setCityTier] = useState([])
    const getCityTier = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: initUrl + admin.cityTire,
            headers: {}
        };
        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setCityTier(res?.data?.result ? res?.data?.result : [])
                    console.log("getCityTier res>>>", res?.data?.result)
                }
                else {
                    setCityTier([])
                    notifyWarning("Something went wrong!!")
                }
            })
            .catch((err) => {
                setLoading(false)
                setCityTier([])
                notifyWarning("Something went wrong!!")
                // console.log("getCityTier reerrs>>>", err)
            })
    }


    // const hotelDetailUpdate = (id: string, field: string, value: any) => {
    //     // console.log(`id>>${id} and field>>${field} and value>>${value.target.value}`)
    //     const newData = [...hotelDetails];
    //     newData[0].max_amount = value?.target?.value;
    //     newData[0].expense_id = id;
    //     setHotelDetails(newData);
    // }

    const hotelDetailUpdate = (e: React.ChangeEvent<HTMLInputElement>, dataValue: any, tier: any) => {
        const { value } = e.target;
        sethotalMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                console.log(item.expense_id, dataValue.id, 'Matchedd', tier)
                // if(item.tier_id != tier.id ){
                if (item.tier_id == tier.id && item.expense_id == dataValue.id) {
                    console.log('updated datatatat????????', item)
                    return {
                        ...item,
                        max_amount: value,
                    };
                }
                return item;
                // }
            });
        });
        console.log(mappedData, hotalMappedData, "<<<mappedData");
    };
    const DaDetailUpdate = (e: React.ChangeEvent<HTMLInputElement>, dataValue: any, tier: any) => {
        const { value } = e.target;
        sethotalMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                console.log(item.expense_id, dataValue, 'Matchedd', tier)
                // if(item.tier_id != tier.id ){
                if (item.tier_id != tier.id) {
                    console.log('updated datatatat????????', item)
                    return {
                        ...item,
                        max_amount: value,
                    };
                }
                return item;
                // }
            });
        });
        console.log(mappedData, "<<<mappedData", dataValue, hotalMappedData);
    };


    const handleDetails = (e: React.ChangeEvent<HTMLInputElement>, id: number, dataValue: any) => {
        const { value } = e.target;
        setMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                // console.log(item.subexpense_id, dataValue.id, 'Matchedd')
                if (item.subexpense_id === dataValue.id) {
                    // console.log('updated datatatat????????', item)
                    return {
                        ...item,
                        unit_cost: value,
                    };
                }
                return item;
            });
        });
        settravelMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                // console.log(item.subexpense_id, dataValue.id, 'Matchedd')
                if (item.subexpense_id === dataValue.id) {
                    // console.log('updated datatatat????????', item)
                    return {
                        ...item,
                        unit_cost: value,
                    };
                }
                return item;
            });
        });

        // console.log(mappedData ,mappedtravelData, "<<<mappedData"  ,hotalMappedData);

    };


    const handleDistanceDetails = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const { value } = e.target;
        // console.log('distance value ?????????????', value)
        setMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                if (item.subexpense_id === id) {
                    return {
                        ...item,
                        max_distance: value,
                    };
                }
                return item;
            });
        });
        settravelMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                if (item.subexpense_id === id) {
                    return {
                        ...item,
                        max_distance: value,
                    };
                }
                return item;
            });
        });

    };
    const handleHotelDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setHotelValue(value)

    };
    const handleDaDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setDaValue(value)

    };
    const handleAmountDetails = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const { value } = e.target;

        setMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                if (item.subexpense_id == id) {
                    return {
                        ...item,
                        max_amount: value,
                    };
                }
                return item;
            });
        });
        settravelMappedData((prevMappedData: any) => {
            return prevMappedData.map((item: any) => {
                if (item.subexpense_id == id) {
                    return {
                        ...item,
                        max_amount: value,
                    };
                }
                return item;
            });
        });
    };

    const handleChangeGrade = (event: React.ChangeEvent<{ value: unknown }>) => {
        setGrade(event.target.value as string);
        getGradePolicy(event.target.value)
    };


    const AddGradePolicy = async () => {
        const tempFilteredData = [...mappedData, ...hotelDetails, ...mappedtravelData, ...hotalMappedData, ...daDetails].filter((item) => item?.expense_id !== "")
        var formdata = new FormData();
        formdata.append("grade_name", grade);
        formdata.append("policies", JSON.stringify(tempFilteredData));

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: initUrl + admin.addGradePolicy,
            headers: {},
            data: formdata
        };
        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    notifySuccess(res?.message)
                }
                navigate('/admin/gradePolicyList')
                console.log("AddGradePolicy res>>>", res)
            })
            .catch((err) => {
                setLoading(false)
                // setHodList([])
            })
    }


    // for grade master data
    const getGrade = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: initUrl + admin.grade,
            headers: {}
        };
        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res?.status === 200) {
                    const newData = res?.data?.result ? res?.data?.result : [];
                    const newArray = newData.filter(item => parseInt(item.policy_status) <= 0);
                    setGradeList(newArray)
                    // console.log(newData, newArray, "<<<<newArray");

                }
                else {
                    setGradeList([])
                }
                // console.log("getGrade res>>>", res)
            })
            .catch((err) => {
                setLoading(false)
                setGradeList([])
                // console.log("getGrade reerrs>>>", err)
            })
    }
    // done

    const getGradePolicy = async (grade) => {
        const formdata = new FormData();
        formdata.append("grade", grade);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: initUrl + admin.getgradePolicy,
            headers: {},
            data: formdata
        };
        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res?.data?.responseCode === 200) {
                    setGradeExpenseData(res?.data?.result)
                    let expensePolicy = res?.data?.result
                    const resultArray: any = [];
                    const resultArray2: any = [];
                    const hotalArray: any = [];
                    expensePolicy.forEach((expenseCategory) => {
                        console.log(expenseCategory, expenseCategory.subexpenses.length, "<<<expenseCategory");
                        if (expenseCategory.has_subexpense.toLowerCase() == "no" && expenseCategory.expense_name.toLowerCase() != "other") {
                            if (expenseCategory.subexpenses.length == 0) {
                                cityTier.forEach((item: any) => {
                                    console.log(item, "<<<");
                                    const hotalItem: any = {
                                        expense_id: expenseCategory.id || "",
                                        tier_id: item?.id,
                                        unit_cost: expenseCategory?.has_unit_cost === "yes" ? expenseCategory?.unit_cost || "" : "",
                                        max_distance: expenseCategory?.has_max_distance === "yes" ? expenseCategory?.max_distance || "" : "",
                                        max_amount: expenseCategory?.has_max_amount === "yes" ? expenseCategory?.max_amount || "" : "",
                                    };
                                    console.log(hotalItem, "<<<");
                                    hotalArray.push(hotalItem);
                                })
                            }
                            sethotalMappedData(hotalArray)

                        }

                        if (expenseCategory.has_subexpense === "yes") {
                            // if (expenseCategory.expense_name == "Hotel") {

                            // }
                            expenseCategory.subexpenses.forEach((subexpense) => {
                                if (subexpense.expenses) {
                                    subexpense.expenses.map((item: any) => {
                                        const resultItem2: any = {
                                            expense_id: item?.expense_id || "",
                                            subexpense_id: item.id || "",
                                            unit_cost: item.has_unit_cost === "yes" ? item.unit_cost || "" : "",
                                            max_distance: item.has_max_distance === "yes" ? item.max_distance || "" : "",
                                            max_amount: item.has_max_amount === "yes" ? item.max_amount || "" : "",
                                        }
                                        resultArray2.push(resultItem2);
                                    })
                                    settravelMappedData(resultArray2)
                                } else {

                                    const resultItem: any = {
                                        expense_id: subexpense.expense_id || "",
                                        subexpense_id: subexpense.id || "",
                                        unit_cost: subexpense.has_unit_cost === "yes" ? subexpense.unit_cost || "" : "",
                                        max_distance: subexpense.has_max_distance === "yes" ? subexpense.max_distance || "" : "",
                                        max_amount: subexpense.has_max_amount === "yes" ? subexpense.max_amount || "" : "",
                                    };
                                    resultArray.push(resultItem);
                                }


                            });
                        }
                    });
                    setMappedData(resultArray)
                    console.log('resultArray?????????????', hotalArray);

                }
                else {
                    setGradeExpenseData([])
                }
                // console.log("getGradePolicy called with gradeid>>", res)
            })
            .catch((err) => {
                setLoading(false)
                setGradeExpenseData([])
                // console.log("getGradePolicy called with gradeid>>", err)
            })
    }

    useEffect(() => {
        getGrade()
        setLoading(true)
    }, [])




    return (
        <div>
            {
                isLoading ?
                    <LoadingSpinner loading={isLoading} />
                    :
                    <>
                        <div className='moduleBorderWithoutPadding'>
                            <div className='m-10px textAlign-Start column'>
                                <span className="bold1Rem commonBlackcolor">Select Grade from this list</span>
                                <div>
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            value={grade}
                                            onChange={handleChangeGrade}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        // disabled={gradedata === undefined ? false : true}
                                        >
                                            <MenuItem value="" disabled>
                                                {"Click to select Grade"}
                                            </MenuItem>
                                            {gradeList?.map((expense: any) => (
                                                <MenuItem key={expense?.id} value={expense?.name}>
                                                    {expense?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <span className="bold1_26Rem commonBlackcolor d-flex m-10px justfyContent-start textAlign-Start">Expense Types</span>
                        {gradeExpenseData.map((expense: any, index) =>
                            <div className='moduleBorderWithoutPadding' key={index}>
                                <div className='d-flex column m-10px justfyContent-start textAlign-Start'>
                                    <div className='bold1Rem commonBlackcolor '>Set Policy for {expense?.expense_name}</div>
                                    <div>
                                        <div className='m-10px'>
                                            <div className='d-flex column nn'>
                                                <div className='addButton'>
                                                    <div className="localBg white d-flex align-center justfyContent-center addButton">
                                                        <span className="light1Rem flentBlack mr-10px">{expense?.expense_name.charAt(0).toUpperCase() + expense?.expense_name.slice(1)}</span>
                                                    </div>
                                                </div>
                                                <>
                                                    {expense?.subexpenses.length > 0 && expense?.subexpenses[0]?.travelType &&
                                                        <>
                                                            {expense?.subexpenses?.map((item: any, key) => (

                                                                <div className='d-flex column aa mt-2px' key={key}>
                                                                    <div className='d-flex alignItem-center m-20px w-15per'>
                                                                        <span className='light1Rem flentBlack m-10px localBg p-10px'>{item?.travelType}</span>
                                                                    </div>
                                                                    {item?.expenses?.map((dataValue: any, id: any) =>
                                                                        <div className='tt d-flex'>
                                                                            <div>
                                                                                <div className='qq d-flex column'>
                                                                                    {
                                                                                        item?.has_unit_cost === 'no' ? "" : item?.has_max_distance == "no" ? "" : item?.has_max_amount == "no" ? "" :
                                                                                            <div className='d-flex'>
                                                                                                <div className='d-flex alignItem-center ml-20px'>
                                                                                                    {dataValue?.has_unit_cost === 'yes' &&
                                                                                                        <div className='w-15per alignItem-center '>
                                                                                                            <span className='light1Rem flentBlack m-10px'>{dataValue?.subexpense_name}</span>
                                                                                                        </div>}
                                                                                                    {dataValue?.has_max_distance === 'yes' &&
                                                                                                        <div className='w-15per alignItem-center '>
                                                                                                            <span className='light1Rem flentBlack m-10px'>{dataValue?.subexpense_name}</span>
                                                                                                        </div>}
                                                                                                    {dataValue?.has_max_amount === 'yes' &&
                                                                                                        <div className='w-15per alignItem-center '>
                                                                                                            <span className='light1Rem flentBlack m-10px'>{dataValue?.subexpense_name}</span>
                                                                                                        </div>}

                                                                                                </div>

                                                                                                {dataValue?.has_unit_cost === 'yes' &&
                                                                                                    <div className='ww mb-20px mr-20px d-flex '>
                                                                                                        <div key={id} className='d-flex bb column'>
                                                                                                            <TextBoxReact
                                                                                                                multiline={false}
                                                                                                                labelText={`Unit cost`}
                                                                                                                img={<img src={bill} />}
                                                                                                                onchangeText={(e: any) => handleDetails(e, dataValue.id, dataValue)}
                                                                                                                placeholder={`Unit cost`}
                                                                                                                defaultValue={dataValue?.unit_cost}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {dataValue?.has_max_distance === 'yes' &&
                                                                                                    <div className='ww mb-20px mr-20px'>
                                                                                                        <div key={id} className='d-flex row bb'>
                                                                                                            <TextBoxReact
                                                                                                                multiline={false}
                                                                                                                labelText={"Maximum Distance (KM)"}
                                                                                                                img={<img src={bill} />}
                                                                                                                onchangeText={(e: any) => handleDistanceDetails(e, dataValue?.id)}
                                                                                                                placeholder={`Enter value`}
                                                                                                                defaultValue={dataValue?.max_distance}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {dataValue?.has_max_amount === 'yes' &&
                                                                                                    <div className='ww mb-20px'>
                                                                                                        <div key={id} className='d-flex row bb'>
                                                                                                            <TextBoxReact
                                                                                                                multiline={false}
                                                                                                                labelText={'Maximum Amount'}
                                                                                                                img={<img src={bill} />}
                                                                                                                onchangeText={(e: any) => handleAmountDetails(e, dataValue?.id,)}
                                                                                                                placeholder={`Enter value`}
                                                                                                                defaultValue={dataValue?.max_amount}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </>
                                                    }
                                                    {/* for meal section */}
                                                    {expense?.subexpenses.length > 0 && !expense?.subexpenses[0]?.travelType &&
                                                        <div className='qq d-flex column'>
                                                            {expense?.subexpenses?.map((item: any, id) => (
                                                                <div className='d-flex'>

                                                                    {(item?.has_unit_cost == 'yes' && item?.has_max_distance == 'yes' && item?.has_max_amount == 'yes') ||
                                                                        (item?.has_unit_cost == 'yes' && item?.has_max_distance == 'yes') || (item?.has_unit_cost == 'yes') ?
                                                                        <div className='w-15per alignItem-center '>
                                                                            <span className='light1Rem flentBlack m-10px'>{item?.subexpense_name}</span>
                                                                        </div>:""
                                                                    }

                                                                    <div>
                                                                    </div>
                                                                    {item?.has_unit_cost === 'yes' &&
                                                                        <div className='ww mb-20px '>
                                                                            <div key={id} className='d-flex row bb'>
                                                                                <TextBoxReact
                                                                                    multiline={false}
                                                                                    labelText={`Unit cost`}
                                                                                    img={<img src={bill} />}
                                                                                    onchangeText={(e: any) => handleDetails(e, item.id, item, "unitCost")}
                                                                                    placeholder={`Unit cost`}
                                                                                    defaultValue={item?.unit_cost}
                                                                                />
                                                                                {/* {console.log(expense?.subexpenses , "<<item.subexpense_name")
                                                                                } */}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {item?.has_max_distance === 'yes' &&
                                                                        <div className='ww mb-20px ml-20px'>
                                                                            <div key={id} className='d-flex row bb'>
                                                                                <TextBoxReact
                                                                                    multiline={false}
                                                                                    labelText={"Maximum Distance (KM)"}
                                                                                    img={<img src={bill} />}
                                                                                    onchangeText={(e: any) => handleDistanceDetails(e, item?.id)}
                                                                                    placeholder={`Enter value`}
                                                                                    defaultValue={item?.max_distance}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {item?.has_max_amount === 'yes' &&
                                                                        <div className='ww mb-20px ml-20px'>
                                                                            <div key={id} className='d-flex row bb'>
                                                                                <TextBoxReact
                                                                                    multiline={false}
                                                                                    labelText={'Maximum Amount'}
                                                                                    img={<img src={bill} />}
                                                                                    onchangeText={(e: any) => handleAmountDetails(e, item?.id,)}
                                                                                    // onchangeText={(e: any) => handleDetails(e, item.id, item, "max_amount")}
                                                                                    placeholder={`Enter value`}
                                                                                    defaultValue={item?.max_amount}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }
                                                </>


                                                {expense?.subexpenses.length == 0 && expense?.has_subexpense.toLowerCase() == "no" && expense?.expense_name.toLowerCase() != "other" &&
                                                    <>
                                                        {cityTier.map((tier: any, id) => (
                                                            <div className='m-10px d-flex row'>
                                                                <div className='m-10px'>
                                                                    <div className='mb-20px '>
                                                                        <div key={id} className='d-flex row bb'>
                                                                            <TextBoxReact
                                                                                multiline={false}
                                                                                labelText={`City Tier`}
                                                                                img={<img src={bill} />}
                                                                                disabled={true}
                                                                                placeholder={`City Tier`}
                                                                                value={tier?.name}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='m-10px'>
                                                                    <div className='d-flex'
                                                                        key={id}
                                                                    >
                                                                        <TextBoxReact
                                                                            multiline={false}
                                                                            labelText={'Maximum amount'}
                                                                            img={<img src={bill} />}
                                                                            onchangeText={(e: any) => hotelDetailUpdate(e, expense, tier)}
                                                                            placeholder={`Enter price`}
                                                                            defaultValue={hotelDetails[0].max_amount}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}

                                                    </>
                                                }


                                            </div >
                                            {/* )} */}
                                        </div>

                                    </div>
                                    {toastContainer()}
                                </div>

                            </div>
                        )}
                        <div className='d-flex row justfyContent-end  flex-wrap'>
                            <div className='d-flex row flex-wrap'>
                                <div className='m-10px'>
                                    <CancelCommonButton
                                        title={"Cancel"}
                                        buttonClick={() => console.log('gcdvhgjwqk')}
                                    />
                                </div>
                                <div className='m-10px'>
                                    <BlueCommonButton
                                        title={"Submit"}
                                        subTitle={""}
                                        buttonClick={() => AddGradePolicy()}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
            }

        </div>
    )
}