import React, { Component } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import './DashBoardStyles.css';
import { expenseUrl, initUrl } from "../../service/url";
import { setData, selectData } from '../../Redux/features/login/loginSlicer';
import { useSelector, useDispatch } from 'react-redux';
import { notifyError, notifySuccess } from "../../components/toast";
import { ServiceCall } from "../../service/config";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const filter = [{ id: 0, day: '12 Months', count: 365 }, { id: 1, day: '6 Months', count: 365 / 2 }, { id: 2, day: '30 Days', count: 30 }, { id: 3, day: '7 Days', count: 7 }];

class DashBoardGraph extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      dataPoints: [],
      pending: [],
      rejected: [],
      approved: [],
      message: '',
      count: 365,
    };
    this.toggleDataSeries = this.toggleDataSeries.bind(this);
  }

  toggleDataSeries(e) {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    this.chart.render();
  }

  getGraphData = async () => {
    this.setState({ loading: true });
    const url = initUrl + expenseUrl.graphExpense;
    const formData = new URLSearchParams();
    formData.append('user_id', this.props.userId);

    await ServiceCall(url, formData, false)
      .then((res) => {
        // console.log("res>>", res);
        if (res.responseCode === 200) {
          this.setState({ message: res?.message })
          this.setState({ dataPoints: res?.result ? res?.result : [] });
          // console.log('grapg??????', this.state.dataPoints)
          this.setState({ approved: res?.result?.approved ? res?.result?.approved : [] })
          this.setState({ rejected: res?.result?.rejected ? res?.result?.rejected : [] })
          this.setState({ pending: res?.result?.pending ? res?.result?.pending : [] })

          

          // console.log(this.state.approved);
        } else {
          notifyError(res?.message);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        // console.log("graph data err>>", err);
      });

  };

  transformedData(originalData) {
    const transformedData = originalData.map(item => {

    const dateComponents = item.label.split('/');
    const formattedDate = new Date(`${dateComponents[1]}/${dateComponents[0]}/${dateComponents[2]}`);
      return {
        x: formattedDate,
        y: parseInt(item.y, 10),
      };
      });
    
      return transformedData;
  }

  componentDidMount() {
    this.getGraphData();
  }
  
  
  render() {
    // // console.log(this.state,this.transformedData(this.state.approved), "<<<this.state");
    const options = {
      theme: "light2",
      animationEnabled: true,
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: "pointer",
        itemclick: this.toggleDataSeries,
      },
      axisX: {
				valueFormatString: "MM/DD/YYYY"
			},
      data: [
          {
          type: "spline",
          name: "Approved",
          lineColor: '#46b34c',
          markerColor: "#46b34c",
          color: "#46b34c",
          lineThickness: 4,
          showInLegend: true,
          yValueFormatString: "#,##0",
          //dataPoints: this.state.approved.slice(0, this.state.count) ? this.state.approved.slice(0, this.state.count) : null,
          dataPoints: this.transformedData(this.state.approved)
        },
        {
          type: "spline",
          name: "Pending",
          lineColor: '#FAB005',
          markerColor: "#FAB005",
          color: "#FAB005",
          lineThickness: 4,
          showInLegend: true,
          yValueFormatString: "#,##0",
          //dataPoints: this.state.pending.slice(0, this.state.count) ? this.state.pending.slice(0, this.state.count) : null,
          dataPoints: this.transformedData(this.state.pending)
        },
        {
          type: "spline",
          name: "Rejected",
          lineColor: 'red',
          markerColor: "red",
          color: "red",
          lineThickness: 4,
          showInLegend: true,
          yValueFormatString: "#,##0",
          //dataPoints: this.state.rejected.slice(0, this.state.count) ? this.state.rejected.slice(0, this.state.count) : null,
          dataPoints: this.transformedData(this.state.rejected)
        },

      ],
    };
    // // console.log('countcount', this.state.count)
    return (
      <div className="moduleBorder">
        <div className="d-flex row space-between m-1_5rem">
          <div>
            <span className="bold1Rem commonBlackcolor">Expense Report</span>
          </div>
          <div className="overflow-x">
            {filter.map((item, id) => (
              <span
                className={`graphFilter ${this.state.count === item.count ? 'selected' : ''}`}
                key={id} onClick={() => this.setState({ count: item?.count })}>
                {item.day}
              </span>
            ))}
          </div>
        </div>
        <div className="overflow-x">
          <CanvasJSChart options={options} onRef={(ref) => (this.chart = ref)} containerProps={{ width: '95%' }} />
        </div>
      </div>
    );
  }
}

export default DashBoardGraph;
