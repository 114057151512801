import React, { useState, useEffect } from "react";
import { TextBoxReact } from "../../components/textBox.tsx";
import people from '../../assets/images/people.svg'
import phone from '../../assets/images/phone.svg'
import city from '../../assets/images/city.svg'
import mail from '../../assets/images/mail.svg'
import { useNavigate, useLocation } from 'react-router-dom';
import { BlueCommonButton, WhiteCommonButton } from '../../components/button.tsx';
import upload from '../../assets/images/upload.svg'

import { NewServiceCall } from '../../service/config.js';
import { admin, expenseUrl, initUrl } from '../../service/url.js'
import { toastContainer, notifySuccess, notifyWarning, notifyError } from '../../components/toast.js';
import { CustomAdminPopUp } from './customAdminPopUp.tsx';

import MultipleSelectChip from "../../components/customMultiSelector.js";
import DropDownCustom from "../../components/customDropdown.js";
import LoadingSpinner from "../../components/loader.tsx";
import { InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { selectData } from "../../Redux/features/login/loginSlicer.js";
export const AddNewEmployee = () => {
    const { state } = useLocation();
    const [allRole, setAllRole] = useState([])
    const [hodList, setHodList] = useState([])
    const [gradeList, setGradeList] = useState([])
    const location = useLocation();
    const [isLoading, setLoading] = useState(false)
    const { data } = location.state || {};
    const [ispopupOpen, setPopup] = useState(false)
    const loginStatus = useSelector(selectData);

    // console.log(allRole, "<<<allRole");

    useEffect(() => {
        setAllRole([
            {
                id: 1,
                name: "EMPLOYEE"
            },
            {
                id: 2,
                name: "HOD"
            },
            {
                id: 3,
                name: "Japaness"
            },
            {
                id: 4,
                name: "HR"
            },
            {
                id: 5,
                name: "Management"
            }
        ])
    }, [])
    // Hod List
    const getHodList = async () => {
        var formdata = new FormData();
        formdata.append("mgm_id", data?.mgmnt_id);
        formdata.append("hod_id", data?.hod_id);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: initUrl + admin.getHodList,
            headers: {},
            data: formdata
        };
        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setHodList(res?.data?.result)
                }
                else {
                    setHodList([])
                }
                console.log("getHodlist res>>>", res)
            })
            .catch((err) => {
                setLoading(false)
                setHodList([])
                console.log("getHodlist reerrs>>>", err)
            })
    }
    // All Roles list for dropdown
    const getAllRoles = async () => {
        const formdata = new FormData();
        formdata.append("name", data?.name);
        formdata.append("email", data?.email);
        formdata.append("phone", data?.phone);
        formdata.append("grade_id", data?.grade_id);
        formdata.append("employe_id", data?.employe_id);
        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: initUrl + admin.getAllRoles,
            headers: {},
            data: formdata
        };

        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setAllRole(res?.data?.result ? res?.data?.result : [])
                }
                else {
                    setAllRole([])
                    notifyError("Something went wrong!")
                }
                // console.log("getAllRoles res>>>", res)
            })
            .catch((err) => {
                setLoading(false)
                notifyError("Something went wrong!")
                setAllRole([])
                // console.log("getAllRoles reerrs>>>", err)
            })
    }
    const getGrade = async () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: initUrl + admin.grade,
            // 
            headers: {}
        };
        setLoading(true)
        await NewServiceCall(config)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setGradeList(res?.data?.result ? res?.data?.result : [])
                }
                else {
                    setGradeList([])
                }
            })
            .catch((err) => {
                setLoading(false)
                setGradeList([])
                console.log("getGrade reerrs>>>", err)
            })
    }

    useEffect(() => {
        getAllRoles()
        getHodList()
        getGrade()
    }, [])

    console.log(data, "<<<data");


    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState('');


    const formateData = (givendate) => {
        let formatedDate = ''
        const joiningDateStr = givendate;
        // Parse the joining date string into a Date object
        const joiningDate = new Date(joiningDateStr);

        // Extract year, month, and day from the joining date
        const year = joiningDate.getFullYear();
        const month = ('0' + (joiningDate.getMonth() + 1)).slice(-2); // Adding 1 to month as it's zero-based
        const day = ('0' + joiningDate.getDate()).slice(-2);

        // Construct the new date string in the required format
        const dob = `${year}-${month}-${day}`;
        console.log(dob);
        return dob
    }

    // console.log(formateData(), "<<<<<<<formateData");
    const [fieldValue, setFieldValue] = useState({
        name: state?.data?.name,
        empID: state?.data?.employe_id,
        email: state?.data?.email || "",
        phoneNo: state?.data?.phone || "",
        grade: data?.grade || "",
        hod: data?.hod || "",
        employe_id: loginStatus?.role?.employe_id,
        base_location_distance: data?.base_location_distance || "",
        role: state?.data?.role ? state?.data?.role : [],
        image: data?.image || "",
        dept: data?.dept || "",
        office: data?.office || "",
        location: data?.location || "",
        designation: data?.designation || "",
        dob: formateData(data?.dob || "") || "",
        joining_date: formateData(data?.joining_date || "") || "",
        dept_code: data?.dept_code || "",
        branch: data?.branch || "",
        cost_center: data?.cost_center || "",
        mgmnt_id: data?.mgmnt_id || "",
        grade_id: data?.grade?.name || "",
        role_id: data?.role_id || "",
        reporting_to: data?.reporting_to ||"",
        hod_id: data?.hod?.employee_id || "",
        // status: "1",
        record_id : data?.id || "0"
    })

    // console.log(fieldValue , "<<<<");
    
    const [userRoles, setUserRoles] = useState(state?.data?.role ? [...state?.data?.role] : []);

    const handleRoleDetails = (e) => {
        setUserRoles((prev) => (prev.some(role => role.id === e.id) ? [
            ...prev?.filter((item)=>item?.id != e.id)
        ] : [...prev, {id: e.id , name:e.role}]));
        // console.log(userRoles, "<<<userRoles");
    }

    const handleInput = (e: any, name: any) => {
        if (e.target?.type == "file") {
            setFieldValue((prevData: any) => ({
                ...prevData,
                [name]: e.target?.files[0]
            }))
        } else {
            setFieldValue((prevData: any) => ({
                ...prevData,
                [name]: e.target?.value
            }))
        }

        // console.log(gradeList , hodList , "<<<");
        
        if (name == "role") {
            setFieldValue((prevData: any) => ({
                ...prevData,
                "role": e.name,
            }))
            // setuserRoles(e)
        } else if (name == "grade") {
            setFieldValue((prevData: any) => ({
                ...prevData,
                "grade": e,
                "grade_id": e?.name
            }))
            console.log(e, "<<<role", userRoles, "<<<<");
        } else if (name == "hod") {
            setFieldValue((prevData: any) => ({
                ...prevData,
                "hod":  e ,
                "hod_id": e?.employe_id ,
                "reporting_to" :e.name
            }))
            console.log(e, name, "<<hode<<");
        }
        // console.log("fieldValue>>", fieldValue, userRoles)
    }

    const validateformData = (data) => {
        let isValid = true
        const requiredkeys = ['empID','email','phoneNo','role','dept','office','location','designation','dept_code','branch','cost_center','grade_id','hod_id','mgmnt_id']
        // console.log(data , "data for validation");
        for(let keyname in data){
            // console.log("keyname >>>>>>",requiredkeys?.includes(keyname));
            if(requiredkeys?.includes(keyname)){
                // console.log(fieldValue[keyname] , "<<<<<");
                if(fieldValue[keyname] == ""){
                    isValid = false
                    notifyError(`${keyname} is required ! please fill all required fields`)
                }
            }   
        }
        return isValid
    }

    const postDetails = async (posttype) => {
        const formdata = new FormData();
        Object.entries(fieldValue).map(([key, value]) => {
            if (key == "role") {
                const commeValue = userRoles?.map((item)=>{ return  item?.name}).join(",")
                // console.log(commeValue, "<<<<" , "<<<userRoles<" ,userRoles);
                formdata.append(key, commeValue)
            } else if(key == "role_id") {
                const commeValuerole_id = userRoles?.map((item)=>{ return item?.id}).join(",")
                formdata.append("role_id", commeValuerole_id)
            } else if(key == "grade") {
                formdata.append("grade", value["name"])
                formdata.append("grade_id", value["name"])
            } else if(key == "hod") {
                formdata.append("hod", value["name"])
            } else {
                formdata.append(key, value)
            }
        })

        let config = {
            method:'post',
            maxBodyLength: Infinity,
            url: `${initUrl}${posttype == "add new" ? "admin/employee" : "admin/employee/update"}`,
            // admin.editEmployee,
            headers: {},
            data: formdata
        };
        
        if(validateformData(fieldValue)){
            setLoading(true)
            await NewServiceCall(config)
                .then((res: any) => {
                    setLoading(false)
                    if (res.status === 200) {
                        notifySuccess(res?.data?.message)
                        navigate('/admin/employeeAdmin')
                    }
                    else {
                        notifyWarning("Something went wrong")
                    }
                })
                .catch((err: any) => {
                    setLoading(false)
                    notifyWarning("Something went wrong")
                })
        }
    }


    return (
        <div>
            <LoadingSpinner loading={isLoading} />
            <div className='moduleBorderWithoutPadding '>
                <div className='d-flex column m-10px justfyContent-start textAlign-Start'>
                    <div className='bold1Rem commonBlackcolor '>
                        {
                            location?.state?.addnew ? "Add new Employee" : "Edit Details"

                        }  </div>
                    <div className="d-flex row flex-wrap gap-20px">
                        <div className='m-10px'>
                            <TextBoxReact
                                multiline={false}
                                labelText="Full Name"
                                img={<img src={people} />}
                                onchangeText={(e: any) => handleInput(e, "name")}
                                placeholder={"Enter your name"}
                                value={fieldValue.name}
                            />
                        </div>

                        <div className='m-10px'>
                            <TextBoxReact
                                multiline={false}
                                labelText="Email"
                                type="email"
                                img={<img src={mail} />}
                                onchangeText={(e: any) => handleInput(e, "email")}
                                placeholder={"Email"}
                                value={fieldValue.email}
                            />
                        </div>

                        <div className='m-10px'>
                            <TextBoxReact
                                multiline={false}
                                labelText="Phone Number"
                                type="number"
                                img={<img src={phone} />}
                                onchangeText={(e: any) => handleInput(e, "phoneNo")}
                                placeholder={"phone number"}
                                value={fieldValue.phoneNo}
                            />
                        </div>
                        <div className='m-10px'>
                            <TextBoxReact
                                multiline={false}
                                labelText="Base location distance"
                                img={<img src={city} />}
                                type="number"
                                onchangeText={(e: any) => handleInput(e, "base_location_distance")}
                                placeholder={"Base location distance"}
                                value={fieldValue.base_location_distance}
                            />
                        </div>
                        <div className='m-10px'>
                            <TextBoxReact
                                multiline={false}
                                labelText="Location Code"
                                type="number"
                                img={<img src={city} />}
                                onchangeText={(e: any) => handleInput(e, "location")}
                                placeholder={"Enter your location  Code"}
                                value={fieldValue?.location}
                            />
                        </div>
                        <div className='m-10px'>
                            <TextBoxReact
                                multiline={false}
                                labelText="Managment Id"
                                img={<img src={city} />}
                                onchangeText={(e: any) => handleInput(e, "mgmnt_id")}
                                placeholder={"Enter mgmnt id"}
                                value={fieldValue?.mgmnt_id}
                            />
                        </div>
                        <div className='m-10px'>
                            <TextBoxReact
                                multiline={false}
                                labelText="Department Code"
                                img={<img src={city} />}
                                onchangeText={(e: any) => handleInput(e, "dept_code")}
                                placeholder={"Enter Depart  name "}
                                value={fieldValue?.dept_code}
                            />
                        </div>

                        <div className='m-10px'>
                            <TextBoxReact
                                multiline={false}
                                labelText="Branch Code"
                                type="number"
                                img={<img src={city} />}
                                onchangeText={(e: any) => handleInput(e, "branch")}
                                placeholder={"Enter branch name "}
                                value={fieldValue?.branch}
                            />
                        </div>
                        <div className='m-10px'>
                            <TextBoxReact
                                multiline={false}
                                labelText="Cost center"
                                img={<img src={city} />}
                                onchangeText={(e: any) => handleInput(e, "cost_center")}
                                placeholder={"Enter cost center  "}
                                value={fieldValue?.cost_center}
                            />
                        </div>
                        <div className='m-10px'>
                            <TextBoxReact
                                multiline={false}
                                labelText="Office"
                                img={<img src={city} />}
                                onchangeText={(e: any) => handleInput(e, "office")}
                                placeholder={"Enter office name "}
                                value={fieldValue?.office}
                            />
                        </div>
                        <div className='m-10px'>
                            <TextBoxReact
                                multiline={false}
                                labelText="Designation"
                                img={<img src={city} />}
                                onchangeText={(e: any) => handleInput(e, "designation")}
                                placeholder={"Enter designation "}
                                value={fieldValue?.designation}
                            />
                        </div>
                        <div className='m-10px'>
                            <TextField
                                id="input-with-icon-textfield"
                                label="DOB"
                                placeholder='Select date here'
                                type='date'
                                className='datepicker'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" className='ml-10px'>
                                        </InputAdornment>
                                    )
                                }}
                                value={fieldValue?.dob}
                                variant="standard"
                                onChange={(e) => handleInput(e, "dob")}
                            />
                        </div>
                        <div className='m-10px'>
                            <TextField
                                id="input-with-icon-textfield"
                                label="Joining Date"
                                placeholder='Select date here'
                                type='date'
                                className='datepicker'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" className='ml-10px'>
                                        </InputAdornment>
                                    )
                                }}
                                value={fieldValue?.joining_date}
                                variant="standard"
                                onChange={(e) => handleInput(e, "joining_date")}
                            />
                        </div>
                        <div className='m-10px'>
                            <TextBoxReact
                                multiline={false}
                                labelText="Department"
                                img={<img src={city} />}
                                onchangeText={(e: any) => handleInput(e, "dept")}
                                placeholder={"Enter Department "}
                                value={fieldValue?.dept}
                            />
                        </div>

                        <div className='m-10px'>
                            <TextBoxReact
                                multiline={false}
                                labelText="Employee ID"
                                img={<img src={people} />}
                                onchangeText={(e: any) => handleInput(e, "empID")}
                                placeholder={"Olivia Rhye"}
                                value={fieldValue.empID}
                            />
                        </div>
                        <div className="m-10px custom-role">
                            {
                                // location?.state?.addnew ? "" :
                                <MultipleSelectChip
                                    icon={<img src={people} />}
                                    lable="Role"
                                    data={allRole}
                                    selectedValue={fieldValue?.role}
                                    setValue={(e) => handleInput(e, "role")}
                                    setItemData={
                                        (e) => {
                                            console.log(e, "<<setItemData<");
                                            handleRoleDetails(e)
                                        }
                                    }
                                    viewKeyName="name"
                                    otherKeyName='role'
                                />

                            }
                        </div>
                        <div className="m-10px">
                            {
                                // location?.state?.addnew ? "" :
                                <DropDownCustom
                                    icon={<img src={people} />}
                                    lable="Select Grade"
                                    data={gradeList}
                                    selectedValue={fieldValue?.grade}
                                    setValue={(e) => handleInput(e, "grade")}
                                    viewKeyName="name"
                                />}
                        </div>
                        <div className="m-10px">
                            {
                                // location?.state?.addnew ? "" :
                                <DropDownCustom
                                    icon={<img src={people} />}
                                    lable="Assign HOD"
                                    data={hodList}
                                    selectedValue={fieldValue?.hod}
                                    setValue={(e) => {
                                        handleInput(e, "hod")
                                    }}
                                    viewKeyName="name"
                                />}
                        </div>
                    </div>
                    <div className="m-10px">

                        <div className="d-flex alignItem-center ">
                            {  
                                fieldValue?.image != "" &&
                                <div className="imgBg">
                                    <img
                                        src={location?.state?.addnew ? URL.createObjectURL(fieldValue?.image) : expenseUrl.initialUrl+"/"+fieldValue?.image}
                                        className="imgBg"
                                        alt="profile image"
                                    // style={{minWidth:"150px"}}
                                    />
                                </div>
                            }
                            <div className="m-10px">
                                <label
                                    htmlFor={`contained-button-meal-file`}
                                >
                                    <div className="white d-flex alignItem-center curser dotted-border">
                                        <img src={upload} className="ml-5px mr-5px" />
                                        <span className="light0_813Rem ml-5px mr-5px commonBlackcolor">
                                            {
                                                fieldValue.image ? fieldValue.image?.name : "Upload Profile image"
                                            }
                                            {
                                                !location?.state?.addnew && "change image"
                                            }
                                        </span>
                                    </div>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id={`contained-button-meal-file`}
                                        name="image"
                                        onChange={(e) => handleInput(e, "image")}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex row'>
                <div className='m-5px'>
                    <WhiteCommonButton
                        title={"Cancel"}
                        subTitle={""}
                        buttonClick={() => navigate('/admin/employeeAdmin')}
                    />
                </div>
                <div className='m-5px'>
                    {
                        location?.state?.addnew ?
                            <BlueCommonButton
                                title={"Submit"}
                                subTitle={""}
                                buttonClick={() => postDetails("add new")}
                            /> :
                            <BlueCommonButton
                                title={"Update"}
                                subTitle={""}
                                buttonClick={() => postDetails("edit")}
                            />

                    }
                </div>
                {toastContainer()}
            </div>
            {
                ispopupOpen &&
                <CustomAdminPopUp
                    close={() => setPopup(false)}
                    popUpType={"employeeEdit"}
                    fileId={1}
                    passTempData={''}
                    buttonClick={() => updateEmployeeDetails()}
                    getValue={(e) => console.log("")}
                />
            }

        </div>
    )
}
